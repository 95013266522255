<template>
    <div id="pythie">
        <Header :user="user" logo="pythie"/>
        <div class="container" style="margin-top: 8%;">
            <div class="dashboard" v-if="!showCollab" >
                <Case  icon="fa-regular fa-user" :isIcon="true" href="/pythie/user-profile" title="Profil" description="" class="profile" :user="user"/>
                <Case  :icon="require('@/assets/pythie/Plan.png')" title="Plan de carrière" description="" class="carreer" href="/pythie/career-plan" :user="user"/>
                <Case :icon="require('@/assets/pythie/suivi.png')"  href="/pythie/activities-tracking" title="Suivi d'activité" description="" :user="user" class="activities"/>
            </div>

            <div class="dashboard d-flex justify-content-center " v-else>
                
                <Case icon="fa-regular fa-user" :isIcon="true" href="/pythie/users" title="Utilisateurs" description="" class="teste_admin" :user="user"/>
            </div>
        </div>
    </div>
    <Chatroom/>
</template>

<script setup>
import Header from '@/components/shared/Header.vue';

import Case from '@/components/pythie/Case.vue';
</script>

<script>
import store from '@/store';
import router from '@/router';
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'DashboardView',
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab
            }
        },
        methods: {
            redirectToTest(){
                router.push({ name: 'test', params: { id: this.user.id } })
            }
        },
        mounted(){
            //console.log('user : ', this.user);
        },
    }
</script>
<style>
#pythie{
        min-height: 100vh;
        background-image: url('@/assets/pythie/Background.jpg');
        background-size: cover; 
        background-repeat: no-repeat;
        overflow-y: auto; 
    }
.content{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}
.dashboard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 50px;
    flex-wrap: wrap;
}
.carreer{
    position: relative;
    top: 75px;
}
.profil{
    position: relative;
    top: 0px; 
}
/* .user{
    position: relative;
    top: 50px; 
} */
.activities{
    position: relative;
    top: 150px;
}
</style>