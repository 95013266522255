<template>
    <div id="pythie">
        <Header :user="userLogged" logo="pythie" page="users"/>
        <back-button />
        <br>
        <div v-if="user.profile">
            <UserProfile :user="user" :id="user.Id"></UserProfile>
        </div>
    </div>
    
</template>
<script>

import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import UserProfile from '@/components/pythie/UserProfile.vue';
import store from "@/store"
import { getUserById } from '@/data/apiPythie';

    export default {
        name: 'UserProfilePythie',
        components:{ Header, BackButton , UserProfile}, 
        data(){
            return {
                id : this.$route.params.id,
                userLogged : store.state.user,
                user : {},
            }
        },
        async mounted(){
            
           this.user= await getUserById(this.id)
        }
    }
</script>