<template>
    <div class="app" id="ness">
      <Header :user="user" page="users" logo="ness"/>
      <div class="row pb-4">
        <back-button />
        <div class="col-3"></div>
        <div class="col-5 cardTitle justify-content-center p-3" v-if="userNess">
            <div class="row">
                <h4 class="col-6" style="float: left;"><b class="textTitle">Prénom : </b>{{ userNess[0].first_name }}</h4>
                <h4 class="col-6 float-right"><b class="textTitle">Nom : </b> {{ userNess[0].last_name }}</h4>
            </div>
            <br>
            <div class=" justify-content-center">
                
                <h5 class=" pr-5 pl-4" style="color: gold;"><b>Poste :</b> {{ userNess[0].extension[0].job_id.title }}</h5>
                
                <!-- <p class="pr-5 pl-5 pt-2" style="color: gold;">{{history.know}} ({{ history.unknow }})VLINKS </p> -->
            </div>
        </div>
        <br>

      </div>
      <br><br>
      <div class="row ">
        <br>
            <div class="col-md-4"></div>
            <i class=" col-md-1 fa-solid fa-magnifying-glass mt-1" style="color: white;font-size: xx-large;"></i>
            <input class="col-md-3 form-control fieldLogin" placeholder="Prenom, Nom ..." v-model="searchHistory" style=""/>
            <div class="col-md-4"></div>
      </div>
      <div class="row p-5 m-1 mr-5 ml-5" style="margin-left: 10%!important;margin-right: 10%!important; max-height: 100vh;overflow-y: auto;" v-if="filteredHistories().length>0">
      <div class="col-md-2 p-4" v-for="history in filteredHistories()" :key="history._id" >
        <div class=" card p-2" style="border-radius: 15%;">
          <div class="row  float-right" style="margin-top: -15%;margin-left:60%">
                <!-- <div class="col-10"></div> -->
                <!-- @click="addFavori(history.id)" -->
                <button class="  btn " style="float:right!important"  >
                  <i class="fa-solid fa-bookmark" :id="'icon-'+history.id" :style="!isFavori(history.id)? 'color: rgb(131, 133, 133)' : 'color :gold'" style="font-size: xx-large  ;"></i>
                </button>
                
              </div>
              <b style="float:left">{{ history.prenom }}</b>
              <b style="float:left">{{ history.nom }} </b>
              <br>
              <p>{{ history.date_histo.substring(0,10)}}</p>

              <div class="row ">
                <div class="col-md-4"><i class="fa-solid fa-link" style="color: gold;"></i></div>
                <div class="col-md-8"><b style="color: gold;">VLINKS {{ history.vlinks }}</b></div>
              </div>
              <br>
              <div class="row justify-content-center" >
                <router-link :to="'/ness/histories/'+ history.id_histo "><button class="btn rounded-circle btn-sm align-items-center"  style="background-color: darkcyan;"><i class="fa-solid fa-arrow-right" color="white"></i></button></router-link>
              </div>
            </div>
      </div>
    </div>
    <div v-else>
      <p class="text-white mt-5" style="margin-top: 10%!important;">Aucun historique</p>
    </div>

    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import store from '@/store';
  import {getHistories, getFavoris, addFavori, removeFavori, getUserNess} from '@/data/apiNess'
  import { useToast } from 'vue-toastification';
import Chatroom from '@/components/chatroom/Chatroom.vue';
  const toast = useToast()
  export default {
    name: "historyNess",
    props: {},
  
    components: {
    BackButton,
    Header,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user,
        userSelected : this.$route.params.id,
        histories : [], 
        searchHistory :'', 
        favoris : [], 
        userNess : undefined
      };
    },
    async mounted(){
      const allUsers = await getUserNess()
        this.userNess = allUsers.filter(item=>{
          return item.id == this.userSelected
        })
        // this.userNess = await getUserById(this.userSelected)
        this.favoris = await getFavoris(this.userSelected)
        this.histories = await getHistories(this.userSelected)
        
        console.log(this.userNess)
    },
    methods:{
        filteredHistories(){
            if(this.searchHistory!='')
                return this.histories.filter(item=>{
                    return item.nom.toLowerCase().includes(this.searchHistory.toLowerCase()) || item.prenom.toLowerCase().includes(this.searchHistory.toLowerCase())
                })
            else return this.histories
        }, 
        isFavori(idCandidat){
          const index = this.favoris.findIndex(item=>{
            return item.id_candidat == idCandidat
          })

          if(index!=-1) return true
          else return false
        },
        async addFavori(idCandidat){

          let response = {}
          const index = this.favoris.findIndex(item => item.id_candidat == idCandidat);

          if(index==-1){
            // console.log("ADD icon-"+idCandidat)
            response = await addFavori(idCandidat, this.user.Id)
            
            document.getElementById('icon-'+idCandidat).style.color = 'gold'
            this.favoris.push({ id_candidat: idCandidat})
          }
          else {
            // console.log("REMOVE icon-"+idCandidat)
            document.getElementById('icon-'+idCandidat).style.color ='rgb(131, 133, 133)'
            response = await removeFavori(idCandidat, this.user.Id)
            this.favoris.splice(index, 1)
          }
          toast.success(JSON.stringify(response))
        },
    }
  
  };
  </script>
  