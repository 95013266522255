<template>
    
    <Header :user="user" logo="themis" page="faq" />
    
    <div class="row p-5">
        <div class="col-md-4 p-3">
            <h2 class="text-white">FAQ</h2>
            <hr>
            <ul class="list-group">
                
                <li id="tovalid" class="btn btn-outline-info m-3 p-3" @click="showToAnswer()">
                    Questions en attente de réponse
                </li>
                <li id="toanswer"  class="btn btn-outline-info m-3 p-3" @click="showAnswered()">
                    Questions répondues
                </li>
            </ul>
        </div>
        <div class="col-md-8 p-3">
            
            <div class="liste p-2" style="max-height: 75vh; overflow-y: auto;">
                <table class="table table-striped" style="width: 100%;border: 1px solid white;">
                    <thead style="font-size: larger;">
                        <th class="textTitle" style="width: 20%;">Date</th>
                        <th class="textTitle" style="width: 20%;">Catégories</th>
                        <th class="textTitle" style="width: 40%;">Questions</th>
                        <th class="textTitle" style="width: 20%;">Statut</th>
                    </thead>
                    <tbody class="text-white">
                        <tr v-for="question in questions.sort((a,b)=> new Date(b.date) - new Date(a.date))" :key="question.id">
                            <td>{{ question.date.substring(0, 10)}}</td>
                            <td>{{ question.categories }}</td>
                            <td>{{ question.question }}</td>
                            <td v-if="question.isValidated && !question.isDeleted">Validé</td>
                            <td v-else-if="!question.isValidated && !question.isDeleted">En attente de validation</td>
                            <td v-else-if="question.isValidated && question.isDeleted">Réfusé</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import {getFAQTitle, getAllQuestionFaq} from '@/data/apiThemis'
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'MyQuestions',
        components: { Header, Chatroom }, 
        data(){
            return{
                user: store.state.user,
                titles : [], 
                questionsToValid : [], 
                allQuestions : [], 
                all : [],
                questions : [], 
                faq : {}, 
            }
        }, 
        async mounted(){
            this.titles = await getFAQTitle()
            const all = await getAllQuestionFaq()
            this.allQuestions = all.filter(item=>{
                return item.userId==this.user.Id
            })
            this.questions = this.allQuestions.filter(item=>{
                    return !item.isAnswered
            })
            document.getElementById('tovalid').classList.add('active')
        },
        methods:{
            close(){
                this.showPopupQuestion = false
            }, 
            showToAnswer(){
                document.getElementById('tovalid').classList.add('active')
                document.getElementById('toanswer').classList.remove('active')
                this.questions = this.allQuestions.filter(item=>{
                    return !item.isAnswered
                })
            }, 
            showAnswered(){
                document.getElementById('toanswer').classList.add('active')
                document.getElementById('tovalid').classList.remove('active')
                this.questions = this.allQuestions.filter(item=>{
                    return item.isAnswered
                })
            }, 
           
               
        }
    }
</script>