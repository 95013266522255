<template>
    <div id="pythie">
        <Header :user="user"  page="user"  logo="pythie"/>
        
        <back-button />
        <div class="container-fluid p-3 " >
            
            <div class="row pr-2  pl-2 pt-4" v-if="userToAssign">
                
                <div class="col-md-12 " align="center">
                    <h1 class="col-md-5 textTitle justify-content-center" >Assigner formation à {{ userToAssign.Username }}</h1>
                    <div class="pt-5 pr-3 pl-3">
                        <form>
                            
                            <div class="row">
                                <div class="col-md-4">
                                    
                                    <div class="card p-2" style="background-color: rgba(255, 255, 255, 0);">
                                        <h2 class="text-white">Toutes les formations</h2>
                                        <br>
                                        <input class="form control input mb-3 mr-3" v-model="search"/>
                                        <div class="row" style="max-height: 45vh;overflow-y: auto;">
                                            <div class="col-md-4 p2" v-for="theme in filteredFormations()" :key="theme.id">
                                                <p :class="!formationAssigned.includes(theme.id)? 'btn btn-outline-info' : 'btn btn-info'" style="width: 100%;" @click="checkTheme(theme.id, theme.titre)">{{theme.titre}}</p>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-7">
                                    <br>
                                    <p class="text-white">Formation(s) à assigner</p>
                                    <div class="card fieldLogin p-5 mb-1" style="min-height: 20vh;">
                                        
                                        <div class="row " v-if="formationAssigned.length>0">
                                            <span class="activeQuestionnaire p-2" v-for="(formation, index) in formationAssigned" :key="formation.id">{{ formationName[index] }} <i class="fa-regular fa-circle-xmark" style="color: red;margin-top: -5px;" @click="checkTheme(formationAssigned[index])"></i></span>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label class="text-white" for="NumberOfQuestion">Date début</label>
                                        <input class="form-control fieldLogin" type="date" v-model="date_debut" id="date_debut" @change="checkDate()"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white" for="NumberOfQuestion">Date fin</label>
                                        <input class="form-control fieldLogin" type="date" v-model="date_fin" id="date_fin" @change="checkDate()"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white" for="time">Statut</label>
                                        <select class="form-control fieldLogin " v-model="statusModel" >
                                            <option v-for="stat in status" :key="stat.id" :value="stat.id" >{{stat.status}} </option>
                                        </select>
                                    </div>
                                    <button type="button"  class="btn btn-block btn-warning text-white" style="font-weight: bold;" @click="assigner" :disabled="formationAssigned.length==0 || date_debut=='' || date_fin==''">Assigner</button>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
                   
                
            </div>
        </div>
    </div>
    <Chatroom/>
</template>
<script>
    import Header from '@/components/shared/Header.vue';
    import { useToast } from "vue-toastification";
    import store from '@/store';
    import functions from '@/data/function';
    import BackButton from "@/components/shared/BackButton.vue";
    import {  assignFormation,getThemeFormation, getUserById , getFormations, getFormationStatus} from '@/data/apiPythie';
import Chatroom from '@/components/chatroom/Chatroom.vue';
    const toast = useToast()
      export default {
        name: 'AssignFormation',
        props: {
         
        },
        components:{ Header, BackButton, Chatroom },
        
        data(){
            return{
                user : store.state.user,
                idUser : this.$route.params.id,
                userToAssign : undefined, 
                themes : [],
                formationAssigned : [], 
                formations : [],
                formationName : [],
                date_debut : '', 
                date_fin : '', 
                statusModel : 1,
                status : [],
                search:''
            }
        },
        async mounted(){
            this.themes = await getThemeFormation()
            this.userToAssign = await getUserById(this.idUser);
            this.formations = await getFormations()
            this.status =await getFormationStatus()
        },
        methods:{
           
            checkTheme(id, titre){
                let index = this.formationAssigned.findIndex(item=> item==id)
                if(index==-1){
                    this.formationAssigned.push(id)
                    this.formationName.push(titre)
                }
                else {
                    this.formationAssigned.splice(index,1)
                    this.formationName.splice(index,1)
                }
            },
            checkDate(){
                if (this.date_debut!='' && this.date_fin!='') {
                    if (new Date(this.date_fin) < new Date(this.date_debut)) {
                        document.getElementById('date_fin').value = this.date_debut;
                    }
                    if(new Date(this.date_debut) > new Date(this.date_fin)){
                        document.getElementById('date_debut').value = this.date_fin;
                    }
                }
            },
            async assigner(){
                const payload = {
                    "date_start": this.date_debut,
                    "date_end": this.date_fin,
                    "progression": 0,
                    "archive": false,
                    "user": this.user.Id,
                    "formation": this.formationAssigned[0],
                    "assignee": parseInt(this.idUser),
                    "status": this.statusModel
                }
                console.log("Payload " ,payload)
                const result = await assignFormation(payload)
                console.log(result)
                toast.success(result)
            },
            back(){
                functions.goBack()
            },
            filteredFormations(){
                if(this.search!=''){
                    return this.formations.filter(item=>{
                        return item.titre.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
                else return this.formations
            }
        },
        
        
      }
</script>
<style>
.transparent {
    background-color: #ffffff00;
    color:white
}
.activeQuestionnaire{
    background-color: white;
    margin: 10px;
    color: black;
}
</style>