<template>
    <div class=" p-5" align="center">
            <h4 class="text-white">Date actuelle : {{ date }}</h4>
            <br>
            <div class="row">
                <div class="col-md-1"></div>
                <button :class="defaultopen? ' btn btn-warning text-white' : 'btn btn-secondary'" class="col-md-3 p-2 text-white" style="border-radius: 4px;font-weight: bold;" @click="defaultopen = true">Planification Quotidienne</button>
                <div class="col-md-4"></div>
                <button :class="!defaultopen? ' btn btn-warning' : 'btn btn-secondary'" class="col-md-3 p-2 text-white" style="border-radius: 4px;font-weight: bold;"  @click="defaultopen = false">Fiche de mission & Objectif</button>
                <div class="col-md-1"></div>
            </div>
            <br>
            <div class="pl-5 pr-5" v-if="defaultopen">
                <div class="row  " style="background-color: rgba(255, 255, 255, 0);border: 3px solid white;border-radius: 10px;max-height: 55vh;overflow-y: auto;">
                    <table class="table border-collapse" style="max-height: 20vh; overflow-y: auto;width: 100%;" >
                        <thead class="textTitle" style="background-color: white;border-radius: 10%; font-weight: bold; font-size: larger;">
                            <th class="text-center">Tâche </th>
                            <th class="text-center">Statut</th>
                            <th class="text-center">Date fin</th>
                            <th class="text-center">Signaler</th>
                        </thead>
                        <tbody class="text-white">
                            <tr v-for="suivi in suivis " :key="suivi.id">
                                <td align="center">{{ suivi.tache }}</td>
                                <td align="center">
                                    <div class="custom-control custom-switch" >
                                        <input type="checkbox" :checked=" suivi.statut " style="font-size: larger;cursor: pointer;" disabled/>
                                    </div>
                                </td>
                                <td align="center">{{ formatDate(suivi.data_fin) }}</td>
                                <td  align="center"><button class="btn rounded-circle"  @click="showPopupSignal(suivi, 'tache')" :style="!suivi.signaler ? 'background-color: grey;' : 'background-color: red;'"><i class="fa-solid fa-xmark" color="white"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <button class="col-md-4 text-white btn btn-warning p-3" style="border-radius: 4px;" @click="showPopup()">Nouvelle tâche</button>
            </div>
            <div class="pl-5 pr-5" v-else>
                <div class="row " style="background-color: rgba(255, 255, 255, 0);border: 3px solid white;border-radius: 10px;max-height: 45vh;overflow-y: auto;">
                    <table class="table border-collapse p-4 " style="max-height: 45vh; overflow-y: auto;width: 100%;" >
                        <thead class="textTitle" style="background-color: white;border-radius: 10%; font-weight: bold; font-size: larger;">
                            <th>Mission </th>
                            <th class="text-center">Statut</th>
                            <th class="text-center">Signaler</th>
                        </thead>
                        <tbody class="text-white">
                            <tr v-for="mission in missions " :key="mission.id">
                                <td>{{ mission.tache }}</td>
                                <td  align="center"><small>{{ mission.statut }}</small>
                                    <div class="progress" style="height: 25px;">
                                        <div class="progress-bar" role="progressbar" :style="'width:'+ mission.statut+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="background-color: grey;"></div>
                                    </div>
                                </td>
                                <td  align="center"><button class="btn rounded-circle" @click="showPopupSignal(mission, 'mission')" :style="mission.signaler? 'background-color: red;':'background-color: grey;'"><i class="fa-solid fa-xmark" color="white"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br><br>
                <b class="text-white mt-2">Progression : {{ calculateAggregation() }} %</b>
                <div class="col-5 progress mt-2 barProgress" style="height: 30px;">
                    <div class="progress-bar pbTest" role="progressbar" :style="'width:'+calculateAggregation()+'%'"  aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <br>
                <button class="col-4 text-white btn btn-warning p-3" style="border-radius: 4px;" @click="showPopupMission()" v-if="showCollab">Nouvelle mission</button>
            </div>
        </div>
        <NewTask v-if="showNewTask" @close="close()" :user="user">
            <button type="button" class="btn  float-right text-white" @click="close()" >x</button>
        </NewTask>
        <NewMission v-if="showNewMission" @close="close()" :user="user">
            <button type="button" class="btn  float-right text-white" @click="close()" >x</button>
        </NewMission>
        <Signal v-if="showSignal" @close="close()" :objet="objet" :type="type" :user="user">
            <button type="button" class="btn  float-right text-white" @click="close()" >x</button>
        </Signal>
</template>

<script>
import NewTask from '@/views/Pythie/popups/NewTask'
import NewMission from '@/views/Pythie/popups/NewMission.vue';
import {getSuivi, getMissions} from '@/data/apiPythie'
import store from '@/store';
import Signal from '@/views/Pythie/popups/Signal.vue';
    export default {
        name: 'UserProfilePythie',
        components:{ NewTask, NewMission, Signal }, 
        props:['user', 'id'],
        data(){
            return {
                // user : store.state.user,
                date : undefined, 
                suivis : [], 
                missions : [],
                defaultopen: true, 
                showNewTask : false, 
                showNewMission : false, 
                showSignal : false,
                showCollab : store.state.showCollab, 
                objet : {}, 
                type : '', 
                aggregation : 0, 
                moyenne : 0
            }
        }, 
        async mounted(){
            var currentDate = new Date();
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            this.date= currentDate.toLocaleDateString('fr-FR', options);
            var id = this.id
            if(!this.id)
                id = store.state.user.Id
            const getData = (async ()=>{
                this.suivis = await getSuivi(id)
                this.missions = await getMissions(id)
            })
            this.$nextTick(()=>{
                getData()
            })  
            
        },
        methods:{
            showPopup(){
                this.showNewTask = true
            },
            close(){
                this.showNewTask = false, 
                this.showNewMission  = false, 
                this.showSignal = false
            },
            showPopupMission(){
                this.showNewMission = true
            },
            showPopupSignal(objet, type){
                this.showSignal = true
                this.objet = objet
                this.type = type
            }, 
            formatDate(date){
                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                return new Date(date).toLocaleDateString('fr-FR', options);
            },
            calculateAggregation(){
                let sum = 0
                this.missions.forEach(item=>{
                    sum+=item.statut
                })
                return (sum/this.missions.length).toFixed(2)
            }
        }  
    }
</script>