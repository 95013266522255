<template>
    <div id="thotea">
        <Header :user="user" logo="thotea"/>
        <div class="container-fluid" style="margin-top: 8%;padding-right: 5%;padding-left: 5%;">
            <div class="dashboard container" v-if="!showCollab">
                <Case :icon="require('@/assets/thotea-icon-test.png')" href="/" title="Test" description="Les tests assigner" class="teste" :user="user"/>
                <Case :icon="require('@/assets/thotea-icon-result.png')" href="/ResultPage" title="Résultat" description="Résultat des testes" :user="user" class="result"/>
            </div>

            <div class="dashboard " v-else>
                <Case :icon="require('@/assets/thotea-icon-test.png')" href="/" title="Test" description="Les tests assigner" class="teste_admin" :user="user"/>
                <Case icon="fa-regular fa-user" :isIcon="true" title="Candidats" description="Assigner test aux candidats" class="candidats_admin" href="/candidates" :user="user"/>
                <Case icon="fa-solid fa-gear" href="/listQuestionnaire" title="Questionnaires" description="Gestion de questionnaires" :isIcon="true" :user="user" class="gestion_admin"/>
                <Case :icon="require('@/assets/thotea-icon-result.png')" href="/ResultPage" title="Mes résultats" description="Résultat des testes" :user="user" class="result_admin"/>
            </div>
        </div>
        <ChatRoom/>
    </div>
    </template>

<script setup>
import Header from '@/components/shared/Header.vue';
import Case from '@/components/Dashboard/Case.vue';
import ChatRoom from '@/components/chatroom/Chatroom.vue';
</script>

<script>
import store from '@/store';
import router from '@/router';
 export default {
        name: 'DashboardView',
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab
            }
        },
        methods: {
            redirectToTest(){
                router.push({ name: 'test', params: { id: this.user.id } })
            }
        },
        mounted(){
            //console.log('user : ', this.user);
        },
    }
</script>
<style>
.content{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    padding: 20px;
}

.dashboard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 50px;
    flex-wrap: wrap;
}
.result{
    position: relative;
    top: 50px;
}
/* .profil{
    position: relative;
    top: 50px; 
} */
/* .user{
    position: relative;
    top: 50px; 
} */
.candidats_admin{
    position: relative;
    top: 50px; 
} 
 .gestion_admin{
    position: relative;
    top: 100px; 
} 
.result_admin{
    position: relative;
    top: 150px;
}



@media only screen and (max-width: 600px) {
    .dashboard {
        padding-top: 30px;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }

    .dashboard > div {
        margin-bottom: 20px;
        width: 100%;
    }
}
@media (max-width: 480px) {
    .container-fluid {
        margin-top: 2%;
        padding-right: 1%;
        padding-left: 1%;
    }

    .case {
        max-width: 100%;
    }
}
</style>