<template>
    <div class="case-container">
        <a :href="href" v-if="user.Id">
            <div class="case">
                <div class="Icon">
                    <img class="imgIcon" :src="icon">
                </div>
                <div class="detail">
                    <h4 class="subtitle">{{ title }}</h4>
                    <p class="description">{{ description }}</p>
                </div>
            </div>
        </a>
    </div>
    
</template>
<script>
export default {
name: 'CaseComponent',
props: ['title', 'description', 'icon', 'user', 'isIcon', 'href'],
}
</script>
<style scoped>
.case-container{
    position: relative;
    display: inline-block;
}
.case{
width: 250px;
height: 250px;
border-radius: 10px 40px 10px 40px;
background-size: 100% 100%;
background-image: url("@/assets/themis/Case.png");
}
.Icon{
display: block;
width: 70px;
height: 70px;
position: absolute;
top: 30px;
left: 30px;
}
.imgIcon{
    width: 100%;
    height: 100%;
}
.detail{
    position: absolute;
    top: 120px;
    padding: 30px;
    text-align: start;
}
.subtitle{
    color: #008DAE;
    font-size: 20px;
    text-transform: capitalize;
}
.description{
    color: #FAB833;
    text-transform: capitalize;
    font-size: 18px;
}
</style>