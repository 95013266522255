<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1; width: 80%;height: 80%;">
            <slot/>
            <div class="row pl-5 pr-5 text-white">
                <div class="col-3">
                    <b>Titre</b> :
                    <br>
                     {{ file.titre }}
                </div>
                <div class="col-1"></div>
                <div class="col-8 liste" style="max-height: 65vh;overflow-y: auto;">
                     <vue-pdf-app style="height: 90vh;" :pdf="pdfUrl"></vue-pdf-app>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
    export default{
        name: 'readFile', 
        props:['file'], 
        components:{
            VuePdfApp
        },
        data(){
            return{
                category : '',
                title : '', 
                question : '', 
                pdfUrl :''
            }
        },
        mounted(){
            this.pdfUrl = this.file.upload
        },
        methods:{
           
        }
    }
</script>