<template>
  <div class="box">
      <slot></slot>
      
      <!-- <h2 class="p-3  resultTitle sticky-top">RESULTATS</h2> -->
      <div class="row p-2 pl-5 pr-5"  v-for="(item, index) in list" :key="item.id" @click="methods(index)" style="cursor: pointer;" >
          
            <!-- <div class="col-1"></div> -->
            <div class="col-md-8  justify-content-center align-items-center " >
              <p class="text-white">{{ item.name }}</p>
              <div class="progress" style="height: 20px;">
                <div class="progress-bar pbTest " role="progressbar" aria-valuenow="10" aria-valuemin="0" 
                  :style="'width:' + Math.round(item.results) + '%'" aria-valuemax="100"> 
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2 "> <p class="pt-3 mt-2 text-white">{{ Math.round(item.results) }}%</p></div>
           
      </div>
    </div>
  
</template>

<script>
export default {
  name: "MyResults",
  props: {
    list: Array,
    methods: function () {},
  },

  data() {
    return {
      index: 0,
    };
  },
  methods:{
    styleByResult(result){
      if(result>=50){
        return "background-color: forestgreen;"
      }
      else if(result<50 && result>=20){
        return "background-color: hsl(49, 90%, 45%);" 
      }
      return "background-color: red;"
    }
  }
};
</script>

<style scoped>
.box {
  width: 100%;
  max-height: 350px;
  margin-top: 10%;
  background-color: #ffffff04;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 1%;
}


.resultTitle{
  background-color: #ffffff16;
  color: white
}
.questionnaireName{
  font-weight: bold;
}
.progress {
  height: 100%;
  width: 100%;
}

.col3 {
  color:white;
  border-radius:20%
}
.questionnaireRes{
  background-color: #f7fafa;
}
</style>
