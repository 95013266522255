<template>
    
    <Header :user="user" logo="themis" page="codeTravail" />
    
    <div class="row p-5">
        <div class="col-md-3 mt-5">
           
            <ul class="list-group "  style="background-color: rgba(255, 255, 255, 0);">
                <li class="btn btn-outline-info m-3 p-3" v-for="title in titles" :key="title.id" :id="'title'+title.id" @click="onChangeTitle(title.id)" style="cursor: pointer;">
                    {{ title.name }}
                </li>
            </ul>
        </div>
        <div class="col-md-9 mt-5" style="border: 5px solid white;border-radius: 4% / 14%;height: 60vh;">
            <div class="row" style="height: 100%">
                <div class="liste col-md-4" style="height: 100%;overflow-y: auto;background-color: white;border-top-left-radius: 10% 13%; border-bottom-left-radius: 10% 13%;">
                    <ul class="list-group">
                        <li class="m-3 list-item-group p-2" v-for="subtitle in subtitles" :key="subtitle.id" :id="'subtitle'+subtitle.id" @click="onChangeSubtitle(subtitle.id)"  style="cursor: pointer;list-style: none;font-size: large;">
                            {{ subtitle.name }} 
                        </li>
                    </ul>
                </div>
                <div class="col-md-8 p-4 pl-5 pr-5 liste" style="max-height: 60vh;overflow-y:auto ">
                    <input class="mt-3 form-control fieldLogin" placeholder="Recherche" v-model="search" style="border: 1px solid rgba(255, 255, 255, 0);"/>
                    <hr>
                    <ul v-if="content.length>0" class="list-group">
                        <li v-for="contenu in filteredContent()" :key="contenu.id" class="list-group-item text-white m-2" style="background-color: #ffffff00;cursor: pointer;border: 1px solid rgba(255, 255, 255, 0.285);" @click="showContent(contenu)">
                            <strong class="  float-left">{{contenu.texte}}</strong>
                            
                            <span class="float-right"><i class="fa-solid fa-angles-right"></i></span>
                        </li>
                    </ul>            
                </div>
            </div>

        </div>
        
        
    </div>
    <LaborCodeContent :source="source" :content="contenu" v-show="show">
        <button type="button" class="btn  float-right text-white" @click="show=false" >x</button>
        <br><br>
    </LaborCodeContent>
    <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import { getTitles, getSousTitles, getContent } from '@/data/apiThemis';
import store from '@/store';
import LaborCodeContent from './popups/LaborCodeContent.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'ThemisLaborCode',
        components: { Header, LaborCodeContent, Chatroom }, 
        data(){
            return{
                user: store.state.user,
                titles : [], 
                idTitle : -1,
                subtitles : [], 
                idSubtitle : -1, 
                content :[], 
                source : '', 
                contenu : {}, 
                show : false, 
                search : ''
                
            }
        }, 
        async mounted(){
            this.titles = await getTitles()
            if(this.titles.length>0){
                this.idTitle = this.titles[0].id
                this.subtitles = await getSousTitles(this.idTitle)
            }
            const updating= async()=>{
                document.getElementById('title' + this.idTitle).classList.add('active');
                if(this.subtitles.length>0){
                    this.idSubtitle = this.subtitles[0].id
                    this.source = this.subtitles[0].link
                    if(document.getElementById('subtitle' + this.idSubtitle)){
                        document.getElementById('subtitle' + this.idSubtitle).classList.add('active');
                    }
                    this.content = await getContent(this.idSubtitle)
                }
            }
            
            this.$nextTick(() => {
                updating()
            });
        },
        methods:{
            filteredContent(){
                if(this.search!='')
                {
                    return this.content.filter(item=>{
                        return item.texte.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
                return this.content
            },
            async onChangeTitle(idTitle){
                document.getElementById('title' + this.idTitle).classList.remove('active');
                this.idTitle = idTitle
                document.getElementById('title' + this.idTitle).classList.add('active');
                this.subtitles = await getSousTitles(this.idTitle)
                
                if(this.subtitles.length>0){
                    this.idSubtitle = this.subtitles[0].id
                    this.source = this.subtitles[0].link
                    const updateContent = async () => {
                        document.getElementById('subtitle' + this.idSubtitle).classList.add('active');
                        this.content = await getContent(this.idSubtitle);
                    };
                    this.$nextTick(() => {
                        updateContent()
                        console.log(this.content)
                    })
                }
                        
                //         this.content = await this.filteredContent(this.idSubtitle)
                //         console.log(this.content)
                //         if(document.getElementById('subtitle' + this.idSubtitle))
                        
                // }
                
            },
            async onChangeSubtitle(idSubtitle){
                document.getElementById('subtitle' + this.idSubtitle).classList.remove('active');
                this.idSubtitle = idSubtitle
                this.content =  await getContent(idSubtitle)
                this.$nextTick(() => {
                    document.getElementById('subtitle' + this.idSubtitle).classList.add('active');
                })
                
            }, 
            showContent(contenu){
                const contenuu= contenu
                console.log(contenu)
                contenuu.contenu = contenu.contenu.replaceAll('\n', '<br>')
                
                this.contenu = contenuu
                this.show = true
            }
          
        }
        
    }
</script>
<style>
.titleThemis{
    background-color: rgba(255, 255, 255, 0);
    color: white; 
}

ul::-webkit-scrollbar{
    background: transparent!important;;
}
</style>