<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<style>
#app {
  font-family:Century Gothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>
