<template>
    <div id="ness">
        <Header :user="user" logo="ness"/>
        <div class="container" style="margin-top: 8%;">
            <div class="dashboard" v-if="!showCollab" >
                <Case :icon="require('@/assets/ness/recherche_icon.png')"  href="/ness/search" title="Recherche" description="Recherche candidat" class="search" :user="user"/>
                <Case :icon="require('@/assets/ness/historique_icon.png')" href="/ness/histories" title="Historiques" description="Historiques des recherches" :user="user" class="historique"/>
                <Case :icon="require('@/assets/ness/reseaux_icon.png')" href="/ness/networks" title="Réseaux" description="Les réseaux"  :user="user" class="network"/>
            </div>
            <div class="dashboard" v-else >
                <Case :icon="require('@/assets/ness/recherche_icon.png')"  href="/ness/requests" title="Nouvelles demandes " :description="'Notification ' +count " class="search" :user="user"/>
                <Case :icon="require('@/assets/ness/historique_icon.png')" href="/ness/histories" title="Utilisateur" description="Gestion des utilisateurs" :user="user" class="historique"/>
                <Case :icon="require('@/assets/ness/reseaux_icon.png')" href="/ness/gest-networks" title="Réseaux" description="Gestion de réseaux"  :user="user" class="network"/>
            </div>
        </div>
    </div>
    <Chatroom/>
</template>

<script setup>
import Header from '@/components/shared/Header.vue';
import Case from '@/components/ness/Case.vue';
</script>

<script>
import store from '@/store';
import router from '@/router';
import { getWaitingNetworks} from '@/data/apiNess';
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'DashboardView',
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab, 
                reseaux : [], 
                count : 0
            }
        },
        methods: {
            redirectToTest(){
                router.push({ name: 'test', params: { id: this.user.id } })
            }
        },
        async mounted(){
            this.reseaux = await getWaitingNetworks()
            this.reseaux.map(item=>{
                if(item.validation==null){
                    this.count ++
                }
            })
            // this.count = this.reseaux.length
        },
    }
</script>
<style>
.content{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}
.dashboard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 100px;
    flex-wrap: wrap;
}
.historique{
    position: relative;
    top: 150px;
}
/* .profil{
    position: relative;
    top: 50px; 
} */
.network{
    position: relative;
    top: 0px; 
}
.search{
    position: relative;
    top: 0px; 
} 
@media (max-width: 768px) {
    .historique {
        top: 50px; 
    }
}

@media (max-width: 730px) {
    .historique {
        top: 30px;
    }
}

</style>