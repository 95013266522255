<template>
  <button
    type="button"
    @click="clickAllResults"
    class=" btn btn-lg btn-warning text-white"  style="width:45%"
  >
    Terminer
  </button>
</template>

<script>
export default {
  name: "ButtonAllResults",

  methods: {
    clickAllResults() {
      this.$router.push("/ResultPage");
    },
  },
};
</script>

<style>
</style>
