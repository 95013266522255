<template>
    <div id="thotea">
        <Header :user="user" logo="thotea" />
        <div class="p-3">         
            <br>
            <Profile :user="user" :job="job" app="thotea"/>
            <br><br>
            <router-link to="/resultPage"><img  src="@/assets/historique_tests.png"/></router-link>
        </div>  
            
    </div>
    <Chatroom />
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
import store from '@/store';
import Header from '@/components/shared/Header.vue';
import Profile from '@/components/shared/Profile.vue';
import axios from 'axios';
import data from '@/data/data';
export default {
    name:"profile-page",
    components:{Header, Profile},
    data(){
        return {
            user : store.state.user, 
            job : {}
        }
    }, 
    mounted(){
        this.getJob()
    },
    methods:{
        getJob(){
            axios.get(data.baseUrl+'authentication/api/api/jobs/'+this.user.profile.job_id)
            .then(response=>{
                this.job = response.data
                
            }).catch(error=> console.log(error))
        }
    }
    
}
</script>
<style>
.cardProfile{
    border-radius: 4% ;
}
</style>