<template>
  <div class="rowHeader row">
    <h1 class="cardTitle p-3">REPONSES DU CANDIDAT </h1>
    
    
  </div>
  <br>
  <h3 class="textTitle" v-if="listName.length>0">Questionnaire : {{ listName[0].name }}</h3>
  <div class="containerca">
    <div class="row p-5">
      <div class="col-sm-4  p-3  " v-for="item in list" :key="item.id" align="center">
        <div class=" card pt-2 ">
          <p class="textTile">
            {{ item.questions.question }}
          </p>
          <div class="row"  :class="isCorrect(item.historic.user_answer, item.questions.correctAnswer)? ' colAns' : ' colAnsFalse'">
          <div :class="isCorrect(item.historic.user_answer, item.questions.correctAnswer)? 'col-12' : 'col-7'" class="caseDetails">
            Réponse du candidat
            <hr>
            <p style="font-size: 25px">{{ item.historic.user_answer }} </p>
          </div>
          <div class="col-5 caseDetails" v-show="!isCorrect(item.historic.user_answer, item.questions.correctAnswer) ">
            Bonne(s) réponse(s)
            <hr>
            <span class="p-2" style="float:right" > {{ item.questions.correctAnswer }}</span>
          </div>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import {isCorrect} from '@/data/utils'
export default {
  name: "CandidatsAnswer",

  props: {
    list: Array,
    methods: function () {},
    listName: Array,
    itemName: Object,
  },
  methods:{
    isCorrect(answers, correctAnswers){
      return isCorrect(answers, correctAnswers)
    }
  }
};
</script>

<style>
.containerca {
  align-items: center;
  /* background-color: lightblue; */
  width: 100%;
  /* margin: 16px; */
}

.rowca {
  margin-top: 16px;
  align-items: center;
  padding: 15px;
}

.colAns {
  /* margin-top: 10px; */
  justify-content: center;
  background-color: #afeed461;
  width: 100%;
  margin: 0;
}
.colAnsFalse {
  justify-content: center;
  background-color: #f407073b;
  width: 100%;
  margin: 0;
}
.colQues {
  background-color: #005A5C;
}
.caseDetails{
  min-height: 25vh;
}
</style>
