<template>
    <div class="app" id="ness">
      <Header :user="user" page="reseaux" logo="ness"/>
      <div class="row">
      <back-button />
      <br>
    </div>
    
    <div class="row justify-content-center">
        <h2 class="cardTitle p-2 pl-5 pr-5" style="font-weight: bold;">Demande d'ajout de lien</h2>
    </div>
        <form @submit.prevent="add()">
            <div class="form-group row pt-5 justify-content-center p-2">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <b class="textTitle label-form pt-2" >Type de réseau</b>
                </div>
                <div class="col-md-4">
                    <select class="form-control  fieldLogin"  v-model="typeReseau">
                        
                        <option v-for="type in typeReseaux" :key="type.id" :value="type.id" >{{type.libelle}} </option>
                    </select>
                </div>
                <div class="col-md-4"></div>
            </div>
            <br>
            <div class=" row form-group p-2">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <b class="textTitle label-form pt-2" >Nom du site</b>
                </div>
                <div class="col-md-4">
                    <input class="form-control fieldLogin " v-model="nameSite">
                </div>
                <div class="col-md-4"></div>
            </div>
            <br>
            <div class="row form-group p-2">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <b class="textTitle label-form pt-2" >URL du Site</b>
                </div>
                <div class="col-md-4">
                    <input class="form-control fieldLogin" v-model="urlSite">
                </div>
                <div class="col-md-4"></div>
            </div>
            <br>
            <div class="row form-group p-2" >
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <b class="textTitle label-form pt-2" >Description </b>
                </div>
                <div class="col-md-4">
                    <textarea class="form-control fieldLogin" v-model="description"></textarea>
                </div>
                <div class="col-md-4"></div>
            </div>
            <br>
            <button class="btn btn-warning btn-lg col-md-2 m-2" style="border-radius: 14% / 50%;"><i class="fa-regular fa-paper-plane"></i>   Envoyer</button>
        </form>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import { typeReseaux, addReseau} from '@/data/apiNess';
  import { useToast } from 'vue-toastification';
  import Chatroom from '@/components/chatroom/Chatroom.vue';

  const toast = useToast()
  import store from '@/store';

  export default {
    name: "addReseau",
    props: {},
  
    components: {
      BackButton,
      Chatroom,
      Header,
  },
  
    data() {
      return {
        user : store.state.user, 
        typeReseaux : [], 
        typeReseau : 0, 
        nameSite : '',
        urlSite : '', 
        description : ''
      };
    },
    async mounted(){
        this.typeReseaux = await typeReseaux()
    },
    methods:{
      
      async add(){
        var object = {
            "user-id": this.user.Id, 
            "type_reseau" : this.typeReseau, 
            "domain" : this.nameSite,
            "url" : this.urlSite, 
            "description" : this.description
        }
        const result = await addReseau(object)
        toast.success(JSON.stringify(result))
        this.typeReseau = 0, 
        this.nameSite = '',
        this.urlSite = '', 
        this.description = ''
      }
    }
  
  };
  </script>
  <style>
  ::-ms-input-placeholder {
    color: white;
  }
</style>