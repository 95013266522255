<template>
    <div class="popup">
        <div class="popup-inner ">
            <slot/>
            <div class="p-4">
                <h2 class="textTitle">Questionnaire pour {{ jobs.filter(item=> item.id==idjob)[0].title }}</h2>
                <hr/>
                <p class="text-white">Choisir un ou des questionnaires  </p>
                <input class="form-control mb-3" type="text" v-model="search" placeholder="Recherche..." />
                <div class="row" style="max-height: 30vh;overflow-y: auto;">
                    <ul v-for="questionnaire in filteredQuestionnaire()" :key="questionnaire.id" class="list-group">
                        <li class="list-group-item" style="background-color: rgba(255, 255, 255, 0);">
                            <button :class="isNotInTheTab(questionnaire)?'btn btn-outline-primary' : 'btn btn-primary'" @click="addIn(questionnaire)">{{questionnaire.Title}}</button>
                        </li>
                    </ul>
                </div>
                <hr/>
                <button class="btn btn-secondary mr-1" style="width: 20%;" @click="togglePopup()" >Fermer</button>
                <button class="btn btn-success " style="width: 20%;" @click="addQuestionnaireToJob();togglePopup()" :disabled="tabQuestionnaire.length==0">Ajouter</button>
            </div>
        </div>
    </div>
</template>
<script>
import data from '@/data/data'
import axios from 'axios'
import { useToast } from "vue-toastification";
const toast = useToast()
 export default {
        name: 'listQuestionnaire',
        props: ['togglePopup', 'questionnaires', 'idjob', 'jobs','questionnaireAssigned'], 
        data(){
            return{
                tabQuestionnaire :this.questionnaireAssigned, 
                allquestionnaire : this.questionnaires,
                search : ''
            }
        },
        methods:{
            filteredQuestionnaire(){
                if(this.search!=''){
                    return this.allquestionnaire.filter(item=>{
                        return item.Title.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
                else return this.allquestionnaire
            },  
            addIn(questionnaire){
                const index = this.tabQuestionnaire.findIndex(item=>item.id==questionnaire.id)
                if(index==-1)
                    this.tabQuestionnaire.push(questionnaire)
                else
                this.tabQuestionnaire.splice(index,1)
            },
            isNotInTheTab(questionnaire){
                
                const index = this.tabQuestionnaire.findIndex(item=>item.id==questionnaire.id)
                if(index==-1)
                    return true
                return false
            }, 
            addQuestionnaireToJob(){
                const payload = {
                    quizzes_id : this.tabQuestionnaire.map(quizz=>quizz.id), 
                    job_id : this.idjob
                }
                //console.log(payload)
                axios.put(data.baseUrl+"thotea/api/updateQuestionnaire/", JSON.stringify(payload))
                .then(response=>{
                    
                    if(!(JSON.stringify(response.data).includes('Error')))
                        toast.success("Questionnaire sur le profil mis à jour.")
                    else toast.error(JSON.stringify(response.data))
                }).catch(error=> console.log(error))
            },
        }
    }
</script>