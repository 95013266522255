<template>
  <div class="box">
    <slot></slot>

    <div class="pb-2">
      <div class="row pr-5 pl-5 rowBox">
        <div class="col-sm-12 p-1">
          <p class="textBox">Bonne réponses par niveau pour <span class="textBgThotea">{{ item.name }}</span></p>
        </div>
        
      </div>

      <div class="row pr-5 pl-5 rowBox">
        <div class="col-sm-12 p-1">
          <p class="textBox">Temps de réponses: <span class="textBgThotea">{{item.timeRemaining}}</span></p>
        </div>
        
      </div>
      <div class="row rowBox pr-5 pl-5 " >
        <div class="col-sm-12 p-1" >
          <p class="textBox">Niveau 1: <span class="textBgThotea"> {{ item.levelOne + "/" + item.countLvl1 }}</span></p>
        </div>
      </div>
      <div class="row rowBox pr-5 pl-5" >
        <div class="col-sm-12 p-1">
          <p class="textBox">Niveau 2: <span class="textBgThotea"> {{ item.levelTwo + "/" + item.countLvl2 }}</span></p>
        </div>
      </div>
      <div class="row rowBox pr-5 pl-5">
        <div class="col-sm-12 p-1">
          <p class="textBox">Niveau 3:  <span class="textBgThotea"> {{ item.levelThree + "/" + item.countLvl3 }}</span></p>
        </div>
      </div>

      <button type="button"  class="btn btnDetails btn-lg mb-2" style="width: 75%;" @click="clickDetails(item.assigned_questionnaire, item.user)">
        Details
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyResults",
  props: {
    message: String,
    item: Object,
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.item);
  },
  methods: {
    
    clickDetails(idQuest, userId) {
      this.$router.push("/DetailResult/" + idQuest + '/' + userId);
    },

  },
};
</script>

<style scoped>
.box {
  /* height: 400px; */
  /* width: 900px; */
  background-color: #ffffff;
  border-radius: 3%;
  margin-right: 5px;
  padding: 5px;
}


.rowBox {
  justify-content: center;
  margin: auto;
  border-radius: 1%;
}

.textBox {
  display: table-cell;
  vertical-align: middle;
  font-size: 24px;
}
.textBgThotea{
  color: #9c4c77;
}
.btnDetails{
  background-color: #9c4c77;
  color: white;
}

.spanBox {
  font-weight: bold;
  font-size: 22px;
}

</style>
