<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #1c3615;">
            <slot/>
            <div class="pl-5 pr-5">
                <br><br>
               <div class="form-group">
                    <label class="text-white">Destinataire</label>
                    <input type="text" class="form-control fieldLogin" placeholder="email separé par ;" v-model="destinataire"/>
                </div>
                <div class="form-group">
                    <label class="text-white">Message</label>
                    <textarea class="form-control fieldLogin" v-model="message"></textarea>
                </div>
                
                <div align="center">
                    <button class="btn btn-secondary" @click="signal()"> Envoyer</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { sendMail, signalTask, signalMission } from '@/data/apiPythie'
import store from '@/store'
import { useToast } from 'vue-toastification'

const toast = useToast()
    export default{
        name: 'signalPopup', 
        props:['objet', 'type'], 
        data(){
            return{
                destinataire : '',
                message : '', 
                user : store.state.user
            }
        },
        mounted(){
            
        },
        methods:{
            async signal(){
                var payload = this.objet
                 payload.signaler = !this.objet.signaler
                 console.log(payload)
                if(this.type=='tache')
                    await signalTask((payload))
                else 
                    await signalMission(payload)

                const tabSplit = this.destinataire.split(';')
                var payloadMail = {
                    "senderMail": "test",
                    "receiverMail": tabSplit,
                    "subject": 'Signal ' + this.type,
                    "body": this.message
                }
                console.log(payloadMail)
                const res = await sendMail(payloadMail)
                console.log(res)
                toast.success(res.status)
                // this.$emit('close')
                // setInterval(()=>{
                //     this.$router.go()
                // }, 1500)
            }
        }
        
    }
</script>