<template>
    <div id="themis">
        <router-view />
    </div>
</template>
<script>
    export default {
        name: 'ThemisView',
    }
</script>
<style scope>
    /* #bg-themis{
        width: 100vw;
        height: 100vh;
        min-width: 100vw;
        min-height: 100vh;
        position: fixed;
        background-image: url('@/assets/themis/Bg-themis.jpg');
        background-repeat: no-repeat;
        background-size: inherit;
    } */
    #themis{
        min-height: 100vh;
        overflow-y: auto;
        background-image: url('@/assets/themis/Bg-themis.jpg');
        background-size: cover; 
        background-repeat: no-repeat; 
    }
    /* * {
        color: #fff;
        opacity: 1;
        font-family: Century Gothic;
    } */
</style>