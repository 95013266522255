<template>
    <div class="container-fluid accueil">
        <br><br>
        <div class="row justify-content-center align-items-center cardLogin"> 
            <div class="col-12 col-md-8">
                <div class="card formulaire">
                    <div class="card-header d-flex justify-content-between align-items-center"  style="border-radius: 1% / 22%">
                        <img src="@/assets/login_page/Baobab.png"/>
                        <div>
                            <router-link to="/profil">
                                <span class="btnProfil text-white" style="font-size: small;">Virtualien</span>
                            </router-link>
                            <a class="btn text-white navbar-brand" aria-expanded="false">
                                <img src="@/assets/profil_photo.png"/>
                            </a>
                            <a @click="logout()" style="cursor: pointer;">
                                <i class="fa-solid fa-arrow-right-from-bracket text-white" style="font-size: larger;"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center content-area mt-3">
                      
                        <div class="col-6 col-md-2 text-center">
                            <a @click="goThotea()">
                                <img src="@/assets/accueil/THOTEA.png" class="img-fluid" style="cursor: pointer;" />
                            </a>
                            <br><br><br><br><br><br>
                            <a @click="goNess()" class="mt-4 d-block">
                                <img src="@/assets/accueil/NESS.png" class="img-fluid" style="cursor: pointer;" />
                            </a>
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <img src="@/assets/accueil/Branche1.png" class="img-fluid branch-img" />
                            <br><br><br>
                            <a @click="goThemis()">
                                <img src="@/assets/accueil/THEMIS.png" class="img-fluid mt-n3" style="cursor: pointer;" />
                            </a>
                            <br><br><br>
                            <img src="@/assets/accueil/Branche3.png" class="img-fluid branch-img mt-n5" />
                        </div>
                        <div class="col-12 col-md-1 text-center d-none d-md-block">
                            <img src="@/assets/accueil/Branche2.png" class="img-fluid" />
                        </div>
                        <div class="col-12 col-md-2 text-center">
                            <img src="@/assets/logo_v5.png" class="img-fluid" style="cursor: pointer;" />
                        </div>
                        <div class="col-12 col-md-1 text-center d-none d-md-block">
                            <img src="@/assets/accueil/Branche5.png" class="img-fluid" />
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <img src="@/assets/accueil/Branche4.png" class="img-fluid branch-img" />
                            <br><br><br>
                            <a @click="goVger()">
                                <img src="@/assets/accueil/VGER.png" class="img-fluid mt-n3" style="cursor: pointer;" />
                            </a>
                            <br><br><br>
                            <img src="@/assets/accueil/Branche6.png" class="img-fluid branch-img mt-n5" />
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <a @click="goVspiel()">
                                <img src="@/assets/accueil/VSPIEL.png" class="img-fluid" style="cursor: pointer;" />
                            </a>
                            <br><br><br><br><br><br>
                            <a @click="goPythie()" class="mt-4 d-block">
                                <img src="@/assets/accueil/PYTHIE.png" class="img-fluid" style="cursor: pointer;" />
                            </a>
                        </div>
                        <chatroom/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Chatroom from '@/components/chatroom/Chatroom.vue';
import store from '@/store';
import { useToast } from 'vue-toastification';

const toast = useToast()
export default {
    name: 'accueilPage',
    data() {
        return {
            user: store.state.user,
            ness: false,
            themis: false,
            thotea: false,
            pythie: false,
            vspiel: false,
            vger: false
        };
    },
    mounted() {
        if (this.user) {
            if (this.user.id == -1)
                this.$router.push('/login');
            console.log(this.user.Groups);
            if (this.user.Groups.includes(1)) {
                this.ness = true;
                this.themis = true;
                this.thotea = true;
                this.pythie = true;
                this.vspiel = true;
                this.vger = true;
            }
            if (this.user.Groups.includes(3)) {
                this.ness = true;
            }
            if (this.user.Groups.includes(2)) {
                this.themis = true;
            }
            if (this.user.Groups.includes(4)) {
                this.thotea = true;
            }
            if (this.user.Groups.includes(5)) {
                this.pythie = true;
            }
            if (this.user.Groups.includes(6)) {
                this.vspiel = true;
            }
            if (this.user.Groups.includes(6)) {
                this.vger = true;
            }
        }
        else
            this.$router.push('/login');
        // 1	global
        // 2	themis
        // 3	ness
        // 4	thotea
        // 5	pythie
        // 6	vspiel
    },
    methods:{
        logout(){
                this.$store.dispatch('logout')
                .then(response=> {
                    console.log(response)
                    // this.toggleViewWithBool(false)
                    this.$router.push('/login')
                }).catch(error=>{
                    console.log(error)
                })
        },
        goPythie(){
            if(this.pythie){
                this.$router.push("/pythie")
            }else toast.warning("Vous n'avez pas accès à cette application")
        }, 
        goThemis(){
            if(this.themis){
                this.$router.push("/themis")
            }else toast.warning("Vous n'avez pas accès à cette application")
        },
        goNess(){
            if(this.ness){
                this.$router.push("/ness/dashboard")
            }else toast.warning("Vous n'avez pas accès à cette application")
        }, 
        goThotea(){
            if(this.thotea){
                this.$router.push("/")
            }else toast.warning("Vous n'avez pas accès à cette application")
        }, 
        goVspiel(){
            if(this.vspiel){
                window.location.href = "http://groupviiorigin.com/index.php"
            }else toast.warning("Vous n'avez pas accès à cette application")
        }, 
        goVger(){
            if(this.vger){
                this.$router.push("/register")
            }else toast.warning("Vous n'avez pas accès à cette application")
        }
    },
    components: { Chatroom }
}
</script>
<style>
    .accueil {
        background-image: url('@/assets/login_page/background_home.jpg')
    }
    .accueil {
    min-height: 100vh;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.card-header {
    background-color: #237eaf;
    border-radius: 2% / 22%;
    max-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header img {
    float: left;
}

.card-header .float-right {
    display: flex;
    align-items: center;
}

.card-header .float-right img {
    margin-left: 0.5rem;
}

.content-area {
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
}

.branch-img {
    width: 100%;
    max-width: 200px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .card-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .content-area {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .branch-img {
        display: none;
    }
}

@media (max-width: 480px) {
    .content-area {
        flex-direction: column;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .card-header {
        height: auto;
    }

    .card-header .float-right {
        margin-top: 0.5rem;
    }
}
</style>
