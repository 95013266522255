<template>
    <div id="pythie">
        <Header :user="userLogged" logo="pythie" page="users"/>
        <back-button />
        <br>
        <ActivitiesUser :user="user" :id="id" ></ActivitiesUser>
    </div>
</template>
<script>
import store from '@/store';

import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import { getUserById } from '@/data/apiPythie';
import ActivitiesUser from '@/components/pythie/ActivitiesUser.vue';
    export default {
        name: 'UserProfilePythie',
        components:{ ActivitiesUser, Header, BackButton }, 
        data(){
            return {
                id : this.$route.params.id,
                userLogged : store.state.user,
                user : {},
                
            }
        }, 
        async mounted(){
           this.user= await getUserById(this.id)
        }
          
    }
</script>