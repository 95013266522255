<template>
    <div class="app" id="thotea">
        <Header :user="user" page="questionnaire" logo="thotea"/>
        <back-button />
        <div class="rowHeader row">
            <h1 class="headerQuestionnaire">REPONSES DU CANDIDAT</h1>
        </div>
        <h1 v-if="listName.filter((x) => x.id == idQuest)[0]">{{ listName.filter((x) => x.id == idQuest)[0].name }}</h1>
        <div class="containerca">
            <div class="container">
                <div class="row rowca" v-for="item in list" :key="item.id">
                    <div class="colQues col-sm-12 pt-3">
                        <p style="font-weight: bold; font-size: 22px; color: white">
                            {{ item.questions.id }}. {{ item.questions.question }}
                        </p>
                    </div>
                    <div :class="isCorrect(item.historic.user_answer, item.questions.correctAnswer)? 'col-sm-12 pt-3 colAns' : 'col-sm-12 pt-3 colAnsFalse'">
                        <p style="font-size: 25px">{{ item.historic.user_answer }} </p>
                        <span style="float:right" v-show="!isCorrect(item.historic.user_answer, item.questions.correctAnswer)"> {{ item.questions.correctAnswer }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <Chatroom />
</template>
<script setup>
    import Chatroom from "@/components/chatroom/Chatroom.vue";
</script>
<script>

import axios from "axios";

import BackButton from "@/components/shared/BackButton.vue";
import {isCorrect} from '@/data/utils'
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import data from '@/data/data';

export default {
    name: "DetailResult",
    props: {},

    components: {
        BackButton,
        
        // NavBar,
        Header
    },

    data() {
        return {
            list: [],
            index: 0,
            item: {},
            user: store.state.user,

            listName: [],
            itemName: {},
            idQuest : this.$route.params.idQuest,
            idUser : this.$route.params.idUser,
        };
    },

    async mounted() {
        let result = await axios.get(
            data.baseUrl + 'thotea/api/historics?user_id=' + this.idUser + '&&questionnaire_id=' + this.idQuest
        );
        //console.log(result.data);
        this.list = result.data;
        this.item = this.list[0];

        let questionnaireName = await axios.get(
            data.baseUrl + 'thotea/api/questiongrade/?userId=' + this.idUser 
        );
        //console.log(questionnaireName.data);
        this.listName = questionnaireName.data;
        this.itemName = this.listName[0];
    },
    methods:{
        isCorrect(answers, correctAnswers){
            return isCorrect(answers, correctAnswers)
        }
    }
};
</script>
  