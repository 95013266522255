<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222de2;width: 80%;">
            <slot/>
            <div class="pl-5 pr-5" style="height: 70vh">
                <div class="row text-white">
                    <div class="col-md-3 float-left liste"  style="max-height: 70vh;overflow-y: auto;">
                        <button v-if="isFavori" class="btn "  @click="removeInFavoris(content.id)"><i class="fa-solid fa-star" :id="'icon'+content.id" color="gold"></i></button>
                        <button v-else class="btn " @click="addFavoris(content.id)" ><i class="fa-solid fa-star" :id="'icon'+content.id" color="white"></i></button>
                        <br><br>

                        <b>Source : </b>
                        <hr>
                        {{ source? source : link }}
                        <br><br>
                        <b class="mt-5">Textes de référence : </b>
                        <hr>
                        {{ content.reference }}
                        <p class="mt-5">Mis à jour : {{ content.updatedDate }}</p>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-8 liste" style="max-height: 70vh;overflow-y: auto;">
                        <h3 class="textTitle">{{ content.texte }}</h3>
                        <p style="text-align: left;" v-html="content.contenu"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { addFavoris, getFavorisByUser} from '@/data/apiThemis';
import store from '@/store';
import { useToast } from 'vue-toastification';
const toast = useToast()
    export default{
        name: 'LaborCodeContent', 
        props:['source', 'content', "link"], 
        data(){
            return{
                myFavoris : [], 
                id : 0,
                listArticleFavoris : [], 
                isFavori : false
            }
        },
        watch: {
            content: {
                handler: 'updateFavoriStatus',
                immediate: true, 
            },
        },
        methods:{
            async updateFavoriStatus() {
                this.myFavoris = await getFavorisByUser(store.state.user.Id);

                if (this.myFavoris.length > 0) {
                    this.id = this.myFavoris[0].id;
                    this.listArticleFavoris = [];

                    for (const id of this.myFavoris[0].id_ContenuArticle) {
                        this.listArticleFavoris.push(id);
                    }

                    const index = this.listArticleFavoris.findIndex(item => item === this.content.id);
                    if (index !== -1) {
                        this.isFavori = true;
                    } else {
                        this.isFavori = false;
                    }
                }
            },
            async addFavoris(idArticle){
                this.listArticleFavoris.push(idArticle)
                console.log(this.listArticleFavoris)
                var payload = {
                    "id_User": store.state.user.Id,
                    "id_ContenuArticle": this.listArticleFavoris,
                    "id_CCArticle": []
                }
                 await addFavoris(payload)
                this.$nextTick(()=>{
                    document.getElementById('icon'+idArticle).style.color = 'gold'
                })
                toast.success("Article ajouté dans favoris")
            },
            async removeInFavoris(id){
                const index = this.listArticleFavoris.findIndex(item=>item===id)
                this.listArticleFavoris.splice(index, 1)
                var payload = {
                    "id_User": store.state.user.Id,
                    "id_ContenuArticle": this.listArticleFavoris,
                    "id_CCArticle": []
                }
                console.log(payload)
                 await addFavoris(payload)
                this.$nextTick(()=>{
                    document.getElementById('icon'+id).style.color = 'white'
                })
                toast.success("Article retiré dans favoris")
                this.$emit('close')
            }
        }
    }
</script>