<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #1c3615;">
            <slot/>
            <div class="pl-5 pr-5">
            <!-- <form> -->
               
                <div class="form-group">
                    <label class="text-white">Nouvelle tâche</label>
                    <textarea class="form-control fieldLogin" v-model="task"></textarea>
                </div>
                <div class="form-group">
                    <label class="text-white">Date fin prévue</label>
                    <input type="date" class="form-control fieldLogin" v-model="date_fin"/>
                </div>
                <div align="center">
                    <button class="btn btn-secondary" @click="addTask()" :disabled="date_fin=='' || task==''"> Ajouter</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import { newTask } from '@/data/apiPythie'
import store from '@/store'
import { useToast } from 'vue-toastification'

const toast = useToast()
    export default{
        name: 'newTask', 
        props:[], 
        data(){
            return{
                task : '',
                date_fin : '', 
                user : store.state.user
            }
        },
        mounted(){
            
        },
        methods:{
            async addTask(){
                var payload={  
                    "tache": this.task,
                    "statut": false,
                    "signaler": false,
                    "data_fin": this.date_fin,
                    "isAddedByAdmin": false,
                    "utilisateur": this.user.Id
                }
                console.log(payload)
                await newTask(payload)
                toast.success("Nouvelle tache ajoutée avec succès")
                this.$emit('close')
                setInterval(()=>{
                    this.$router.go()
                }, 1500)
            }
        }
        
    }
</script>