<template>
    
    <Header :user="user" logo="themis" page="favoris" />
    <div class="row">
        <back-button />
        
    </div>  
    <div class="row p-5">
        <div class="col-md-4">
            <h2 class="text-white">Mes favoris</h2>
            <hr>
            <ul class="list-group p-5" >
                <li class="active m-2 mt-4 p-3 btn " @click="contents=contentsArticle;" >
                    Droit du travail
                </li>
                <li class="active m-2 mt-5 p-3 btn text-white" @click="contents=contentsCollection">
                    Convention collectives
                </li>
            </ul>
        </div>
        <div class="liste col-md-8 p-5 text-white" style="max-height: 75vh;overflow-y: auto;">
            <ul class="list-group " v-if="contents.length>0">
               <li class="list-group-item m-2 p-3" v-for="content in contents" :key="content.id" style="border: 1px solid white;background-color: rgba(255, 255, 255, 0);" @click="showContent=true, contenu=content">
                    {{ content.texte }}
               </li> 
            </ul>
            <p class="mt-5" v-else>Pas de favoris</p>
        </div>
    </div>
    <LaborCodeContent source="" :content="contenu" v-show="showContent">
        <button type="button" class="btn  float-right text-white" @click="showContent=false" >x</button>
        <br><br>
    </LaborCodeContent>
    <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import LaborCodeContent from './popups/LaborCodeContent.vue'
import store from '@/store';
import {getLaborCodeById, getFavorisByUser } from '@/data/apiThemis'
import Chatroom from '@/components/chatroom/Chatroom.vue';
export default{
    name: 'myFavoris',
    components:{
    Header, LaborCodeContent, BackButton,
    Chatroom
},
    data(){
        return {
            user : store.state.user,
            myFavoris : [], 
            contentsArticle : [], 
            contentsCollection : [], 
            showContent : false, 
            contenu : {},
            contents : []

        }
    }, 
    async mounted(){
        this.myFavoris = await getFavorisByUser(this.user.Id)
        
        if(this.myFavoris.length>0){
            this.myFavoris[0].id_ContenuArticle.forEach(async(item)=>{
                const laborcode = await getLaborCodeById(item)
                this.contentsArticle.push(laborcode)
            })
            this.myFavoris[0].id_CCArticle.forEach(async(item)=>{
                const ccarticle =item
                this.contentsCollection.push(ccarticle)
            })
        }
        this.contents = this.contentsArticle

    },
    methods:{
       
    }
}
</script>