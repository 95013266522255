<template>
    
    <Header :user="user" logo="themis" />
    <div class="container " style="margin-top: 5%;" v-if="!showCollab">
        <div class="top ">
            <CaseComponent title="Code du travail" description="Article concernant le code du travail" :icon="require('@/assets/themis/cdt.png')" :user="store.state.user" href="/themis/laborcode" />
            <CaseComponent title="Mon entreprise" description="Informations " :icon="require('@/assets/themis/entreprise.png')" :user="store.state.user" href="/themis/my-company" />
        </div>
        <div class="bottom">
            <CaseComponent title="Conventions collectives" description="teste" :icon="require('@/assets/themis/collectives.png')" :user="store.state.user" href="/themis/collective-agreements" />
            <CaseComponent title="FAQ" description="Foire aux questions" :icon="require('@/assets/themis/faq.png')" :user="store.state.user" href="/themis/faq" />
        </div>
    </div>
    <div class="container " style="margin-top: 5%;" v-else>
        <div class="top">
            <CaseComponent title="Utilisateurs" description="Liste des utilisateurs" :icon="require('@/assets/themis/cdt.png')" :user="store.state.user" href="/themis/users" />
            <CaseComponent title="Mon entreprise" description="Informations " :icon="require('@/assets/themis/entreprise.png')" :user="store.state.user" href="/themis/my-company"  style="margin-left: 60%!important;" />
        </div>
        <div class="bottom">
            <CaseComponent title="Conventions collectives" description="teste" :icon="require('@/assets/themis/collectives.png')" :user="store.state.user" href="/themis/collective-agreements" hidden />
            <CaseComponent title="FAQ" description="Foire aux questions" :icon="require('@/assets/themis/faq.png')" :user="store.state.user" href="/themis/faq-gest" class="ml-5" style="margin-left: 13%!important;" />
        </div>
    </div>
    <Chatroom/>
</template>
<script setup>
    import CaseComponent from '@/components/Themis/CaseComponent.vue';
    import store from '@/store';
    import Header from '@/components/shared/Header.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
    export default {
        name: 'ThemisHome',
        components: {Header}, 
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab, 
            }
        }
    }
</script>
<style scoped>
    .container{
        overflow: auto !important;
        height: 100%;
        padding-bottom: 200px;
        width: 100%;
    }
    .container::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .top, .bottom{
        display: flex;
        align-items: space-between;
        justify-content: space-between;
        margin: 20px auto;
    }
    
    .top{
        padding-right: 25%;
    }
    .bottom{
        padding-left: 25%;
    }
   
</style>