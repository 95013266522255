<template>
    <div class="case-container">
        <a :href="href" v-if="user.Id">
            <div class="case">
                <div v-if="isIcon" class="faIcon fatop"><i :class="icon"></i></div>
                <img v-else :src="icon" class="icon" >
                <div class="detail">
                    <h4 class="case-title">{{ title }}</h4>
                    <p class="case-description">{{ description }}</p>
                </div>
            </div>
        </a>
    </div>
</template>
<script>
export default {
name: 'CaseComponent',
props: ['title', 'description', 'icon', 'user', 'isIcon', 'href'],
}
</script>
<style scoped>
.case{
width: 260px;
height: 260px;
border-radius: 10px 40px 10px 40px;
background-size: 100% 100%;
background-image: url("../../../src/assets/ness/case_recherche.png");
}
.icon{
    display: block;
width: 70px;
height: 70px;
position: absolute;
top: 30px;
left: 30px;
}
.detail{
    position: absolute;
    top: 120px;
    padding: 30px;
    text-align: start;
}
.subtitle{
    color: #008DAE;
    font-size: 20px;
    text-transform: capitalize;
}
.description{
    color: #FAB833;
    text-transform: capitalize;
    font-size: 18px;
}
.description{
/* position: relative;
display: flex;
flex-direction: column;
top: 80px;
left: 30px;
align-items: flex-start; */
}
.case-title{
color: #8a4c76;
}
.case-description{
color: #fab833;
}
.faIcon{
font-size: 100px;
text-align: start;
color: #ffffff;
position: relative;
left: 60px;
top: 30px;
margin: 0;
padding: 0;
border: 0;
}
.fa{
margin: 0;
padding: 0;
}
.faTop{
top: 0
}
.faDescription{
position: relative;
display: flex;
flex-direction: column;
top: 45px;
left: 30px;
align-items: flex-start;
}
</style>