<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #1c3615;">
            <slot/>
            <div class="pl-5 pr-5">
            <!-- <form> -->
                <div class="form-group">
                    <label class="text-white">Titre de mission</label>
                    <textarea class="form-control fieldLogin" v-model="title"></textarea>
                </div>
                <div class="form-group">
                    <label class="text-white">Mission</label>
                    <textarea class="form-control fieldLogin" v-model="mission"></textarea>
                </div>
                <div class="form-group">
                    <label class="text-white">Date fin prévue</label>
                    <input type="date" class="form-control fieldLogin" v-model="date_fin"/>
                </div>
                <div align="center">
                    <button class="btn btn-secondary" @click="addMission()"> Ajouter</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import { newMission } from '@/data/apiPythie'
import store from '@/store'
import { useToast } from 'vue-toastification'

const toast = useToast()
    export default{
        name: 'newTask', 
        props:[], 
        data(){
            return{
                mission : '',
                title : '',
                date_fin : '', 
                user : store.state.user
            }
        },
        mounted(){
            
        },
        methods:{
            async addMission(){
                var payload={  
                    "tache": this.mission,
                    "statut": 0,
                    "signaler": false,
                    "data_fin": this.date_fin,
                    "isAddedByAdmin": false,
                    "utilisateur": this.user.Id
                }
                
                console.log(payload2)
                const result = await newMission(payload)
                console.log(result)
                var payload2 = {
                    "titre" : this.title, 
                    "utilisateur" : this.user.Id, 
                    // "mission" : 
                }
                toast.success("Nouvelle mission ajoutée avec succès")
                this.$emit('close')
                setInterval(()=>{
                    this.$router.go()
                }, 1500)
            }
        }
        
    }
</script>