<template>
    <div class="case-container">
    <a :href="href" v-if="user.Id">
        <div class="case">
            <div v-if="isIcon" class="faIcon fatop"><i :class="icon" ></i></div>
            <img v-else :src="icon" class="icon" style="width: 25%;">
            <div :class="{description:!isIcon, faDescription:isIcon}">
                <h4 class="case-title">{{ title }}</h4>
                <p class="case-description">{{ description }}</p>
            </div>
        </div>
    </a>
    </div>
</template>
<script>
export default {
name: 'CaseComponent',
props: ['title', 'description', 'icon', 'user', 'isIcon', 'href'],
}
</script>
<style scoped>
.case{
width: 280px;
height: 280px;
border-radius: 10px 50px 10px 50px;
padding: 5%;
background-image: url("../../../src/assets/pythie/case.png");
}
.icon{
display: block;
position: relative;
top: 30px;
left: 60px;
font-size: 80px;
}
.description{
position: relative;
display: flex;
flex-direction: column;
top: 80px;
left: 30px;
align-items: flex-start;
}
.case-title{
color: #8a4c76;
}
.case-description{
color: #fab833;
}
.faIcon{
font-size: 100px;
text-align: start;
color: #ffffff;
position: relative;
left: 50px;
top: 18px;
margin: 0;
padding: 0;
border: 0;
}
.fa{
margin: 0;
padding: 0;
}
.faTop{
top: 0
}
.faDescription{
position: relative;
display: flex;
flex-direction: column;
top: 45px;
left: 30px;
align-items: flex-start;
}
</style>