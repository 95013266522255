<template>
    <div id="thotea">
        <div v-show="userLogged">
            <Header :user="userLogged" logo="vger" />
        </div>
        <back-button />
        <br>
    <h2 class=" text-center pt-4 title"  >Fiche de renseignements </h2>
    <hr/>
    <form class="pl-5 pr-5 " @submit.prevent="createProfile">
        <div class="row">
            <div class="col-md-6 pl-5 pr-5 ">
                <div class="form-group">
                    <label class="label" for="name">Nom</label>
                    <input type="text" class="form-control input" id="name"  v-model="user.last_name" required/>
                </div>
                <div class="form-group">
                        <label class="label" for="firstname">Prénom</label>
                        <input type="text" class="form-control input" id="firstname" v-model="user.first_name" required/>
                </div>
                <div class="form-group">
                    <label class="label" for="email">Adresse email</label>
                    <input type="email" class="form-control input"
                    v-bind:class="{ 'is-invalid' : !validEmail(user.email) && emailBlured} "
                      v-on:blur="emailBlured = true"
                    id="email" v-model="user.email" required/>
                    <div class="invalid-feedback">Une adresse courriel valide est requise</div>
                </div>
                <div class="form-group">
                    <label class="label" for="nickname">Nickname</label>
                    <input type="text" class="form-control input" id="nickname" v-model="user.nickname" required/>
                </div>
                <div class="row">
                    <div class="col-md-8 form-group">
                        <label class="label" for="codePostal">Adresse</label>
                        <input type="text" class="form-control input" id="adress" v-model="user.street_name" required/>
                    </div>
                    
                    <div class="col-md-4 form-group">
                        <label class="label" for="codePostal">Code postal</label>
                        <input type="number" class="form-control input" id="codePostal" v-model="user.postal_code" required/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label" for="ville">Ville</label>
                    <input type="text" class="form-control input" id="ville" v-model="user.city" required/>
                </div>
                <div class="form-group">
                    <label class="label" for="nationality">Nationalité</label>
                    <input type="text" class="form-control input" id="nationality" v-model="user.nationality" required/>
                </div>
            </div>

            <div class="col-md-6 pl-5 pr-5">
                <div class="form-group">
                    <label class="label" for="profil">Profil technique </label>
                    <select class="form-control input" id="profil" v-model="user.job_id" required>
                        <option :value="profil.id" v-for="profil in profils" :key="profil.id">{{profil.title}}</option>
                    </select>
                </div>
                
                <div class="form-group">
                    <label class="label" for="phoneNumber">Numéro de téléphone</label>
                    <input type="tel" class="form-control input" id="phoneNumber" v-model="user.mobile_num" required>
                </div>
                <div class="form-group">
                    <label class="label" for="dob">Date de naissance</label>
                    <input type="date" class="form-control input" id="dob" v-model="user.dob" required/>
                </div>
                <div class="form-group">
                    <label class="label" for="disponibilite">Disponibilité</label>
                    <input type="date" class="form-control input" id="disponibilite" v-model="user.availability" required/>
                </div>
                <div class="form-group">
                    <label class="label" for="mdp">Mot de passe</label>
                    <input type="password" class="form-control input" v-model="user.password" id="mdp" required/>
                </div>
                <div class="form-group">
                    <label class="label" for="recruteur">Recruteur </label>
                    <select class="form-control input" id="recruteur" disabled>
                        <option :value="0">recruteur1</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="label" for="salaire">Prétention salariale</label>
                    <input type="number" class="form-control input" id="salaire" v-model="user.salary_expectations" required/>
                </div>
            </div>
            
        </div>
        <div align="center">
            <button type="submit" style="width: 50%;"  
                    :disabled="!isFormValid()"
                    class="btn btn-block btn-warning text-white p-3"  >
                Valider
            </button>
        </div>
    </form>
</div>
</template>

  <script>
import axios from 'axios'
import data from '@/data/data'
import { useToast } from "vue-toastification";
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import store from '@/store';
const toast = useToast()
  export default {
    name: 'indexPage',
    components:{Header, BackButton},
    data(){
        return {
            profils : [], 
            emailBlured : false,
            user: {
                first_name: '',
                last_name: '',
                nickname: '',
                password: '',
                email: '',
                postal_code: '',
                city: '',
                job_id: '',
                nationality: '',
                mobile_num: '',
                dob: '',
                availability: '',
                salary_expectations: 0,
                recruteur_id: 0,
            },
            userLogged:store.state.user,
        }
    },
    mounted(){ 
        axios.get(data.baseUrl+'authentication/api/api/jobs/')
            .then(response=>{
                this.profils = response.data
            }).catch(error=> console.log(error))
    },
    
    methods:{
        async createProfile(){
            this.user.admin=0;
            this.user.username=this.user.nickname;
            
            const payload = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(this.user)
            }
            try{
                const response = await(await fetch(data.baseUrl+'thotea/register/', payload)).json()
                try {
                    if (response.Message !=='Profile created successfully')
                        throw new Error(response.Message)

                }
                catch(e){
                    toast.error(e.message)
                    return
                }
                this.$store.dispatch('login',  {"username" : this.user.email,  "password": this.user.password})
                    .then(response1=> {
                        if(response1.data.Error) {
                            toast.error(response1.data.Error)
                        }
                        this.$router.push('/') 
                    })
                toast.success('Creation de profil avec succès')
                
            }
            catch (e) {
                toast.error('Une erreur est survenu lors de la creation de votre profil');
            }
            
            
        },
        isFormValid(){
           if(this.user.availability=='' || this.user.last_name=='' || this.user.first_name=='' || this.user.city=='' || 
                this.user.dob=='' || this.user.email=='' || this.user.postal_code=='' || this.user.job_id=='' || this.user.mobile_num=='' ||
                this.user.nickname==''
            )
                return false
            return true
        },
        validEmail : function(email) {
          var re = /(.+)@(.+){2,}\.(.+){2,}/;
          return re.test(email.toLowerCase());
        },
        addCandidate(){
            var newCandidate = {
                ...this.user,
                ...this.user,
                "admin": 0,
                "nickname": this.username, 
            }
            axios.post(data.baseUrl+'thotea/register/', newCandidate)
                .then(res=>{
                    toast.success(res.data.Message)
                    if( res.data.Message=='Profile created successfully')
                    {
                        this.$store.dispatch('login',  {"username" : newCandidate.email,  "password": newCandidate.password})
                            .then(response1=> {
                                console.log(response1)
                                if(response1.data.Error) {
                                    toast.error(response1.data.Error)
                                    this.$router.push('/')  
                                }
                                else{
                                    this.$router.push('/')  
                                } 
                            }).catch(error1=> {
                                console.log(error1)  
                            })
                    }
                })
                .catch(error=>console.log(error))
            
        }
    }

  }
  </script>
 <style scoped>
    .title{
        color: #fab833;
        font-weight: 600;
        background-color: #ffffff00;
    }
    .label{
        color: #fab833;
    }
    .input{
        background-color: #9a4c7660;
        color: #fff;
    }
    .input:hover, .input:active, .input:focus{
        background-color: #7e325a60;
    }
    option{
        background-color: hsl(330, 53%, 25%)
    }
    .navbar{
        /*background-image: url('@/assets/fond_site_web.png');*/
        color: white;
    }
    .label{
        float: left;
    }
    .text-fiche{
        color : white;
        font-weight: bold;
        font-size: x-large;
    }
    .thoteaBg{
        background-color: rgba(255, 255, 255, 0.029);
        align-items: center;
    }
    .bgForm{
        background-color: #fcfbfb74;
    }
</style>
  
  