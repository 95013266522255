<template>
    
    <Header :user="user" logo="themis" page="faq" />
    
    <div class="row p-5">
        <div class="col-md-4 p-3">
            <h2 class="text-white">FAQ</h2>
            <hr>
            <ul class="list-group">
                <li class="btn btn-outline-info m-3 p-3" v-for="title in titles" :key="title.id" @click="onchangeTitle(title.id)" v-show="title.titre!=''" style="border-radius: 1%/2%;" :id="'title'+title.id">
                    {{ title.titre }} 
                </li>
            </ul>
        </div>
        <div class="col-md-8 p-3" >
            <button class="btn round-circle  btn-primary float-right" @click="showPopupQuestion=!showPopupQuestion"><i class="fa-solid fa-question"></i></button>
            <p class=" text-white float-right mt-2 mr-2">Poser une question</p>
            <div class="row pl-5 pr-5">
                <input class="form-control fieldLogin" placeholder="Recherche" v-model="search" style="border: 1px solid rgba(255, 255, 255, 0);"/> 
            </div>
            <br>
            <div class="liste row p-2" style="max-height: 90vh;overflow-y: auto;">
                <div class="card pr-5 pl-5 pt-3 m-3" v-for="faq in filteredFaq()" :key="faq.id" v-show="faq.question!==''" style="background-color: rgba(255, 255, 255, 0);border: 1px solid goldenrod;">
                    
                    <b class="textTitle"><i class="fa-regular fa-comments" style="font-size: larger;"></i>  {{ faq.question}}</b>
                    <hr>
                    <p class="text-white" :id="'texte'+faq.id"> 
                        {{showText( faq.reponse, 300 )}}
                    </p>
                    <a class="text-white" :id="'linkplus'+faq.id" @click="moreText(faq.id, faq.reponse)" >Voir plus</a>
                    <a class="text-white" :id="'linkmoins'+faq.id" @click="lessText(faq.id,showText( faq.reponse, 300 ))" hidden>Voir moins</a>
                    <br>
                </div>
            </div>
        </div>

        
    </div>
    <AddQuestion :categories="titles" v-if="showPopupQuestion" @close="close" >
        <button type="button" class="btn  float-right text-white" @click="showPopupQuestion=false" >x</button>
        <h2 class="textTitle">Posez votre question</h2>
        <hr>
    </AddQuestion>

    <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import {getFAQTitle, getFAQByTitle} from '@/data/apiThemis'
import AddQuestion from './popups/AddQuestion.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';

 export default {
        name: 'ThemisFAQ',
        components: { Header, AddQuestion, Chatroom }, 
        data(){
            return{
                user: store.state.user,
                titles : [], 
                idTitle : -1, 
                faqs :[], 
                showPopupQuestion : false,
                search : ''
            }
        }, 
        async mounted(){
            this.titles = await getFAQTitle()
            if(this.titles.length>0){
                this.idTitle = this.titles[0].id
                this.faqs = await this.getFaq(this.idTitle)
            }
            if(this.idTitle!=-1 && document.getElementById('title'+this.idTitle))
                document.getElementById('title'+this.idTitle).classList.add('active')
        },
        methods:{
            filteredFaq(){
                if(this.search!=''){
                    return this.faqs.filter(item=>{
                        return item.question.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
                return this.faqs
            },
            async getFaq(id){
                return await getFAQByTitle(id)
            }, 
            async onchangeTitle(idTitle){
                document.getElementById('title'+this.idTitle).classList.remove('active')
                this.idTitle = idTitle
                document.getElementById('title'+this.idTitle).classList.add('active')
                this.faqs = await this.getFaq(this.idTitle)
            },
            close(){
                this.showPopupQuestion = false
            },
            
            showText(longText, textlength){
                let textToShow = longText.slice(0, textlength)
                if(textlength<longText.length){
                    textToShow+='...'
                }
                return textToShow
            }, 
            moreText(id, longText){
                document.getElementById('texte'+id).textContent = longText
                document.getElementById('linkplus'+id).hidden = true
                document.getElementById('linkmoins'+id).hidden=false
            },
            lessText(id, lessText){
                document.getElementById('texte'+id).textContent = lessText
                document.getElementById('linkplus'+id).hidden = false
                document.getElementById('linkmoins'+id).hidden = true
            }
           
        }
    }
</script>
<style>
.liste::-webkit-scrollbar{
    background: rgba(255, 255, 255, 0);
}
</style>