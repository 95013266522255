<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1;">
            <slot/>
            <div class="pl-5 pr-5">
            <!-- <form> -->
                <div class="form-group ">
                    <label class="text-white">Catégorie</label>
                    <select class="form-control fieldLogin" v-model="category">
                        <option value="">Selectionner une catégorie</option>
                        <option :value="category.titre" v-for="category in categories" :key="category.id">
                            {{ category.titre }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="text-white">Titre</label>
                    <input class="form-control fieldLogin" v-model="title"/>
                </div>
                <div class="form-group">
                    <label class="text-white">Question</label>
                    <textarea class="form-control fieldLogin" v-model="question"></textarea>
                </div>
                <div class="row pr-5 pl-5 pt-3">
                    <button class="col-6 btn btn-primary" @click="addQuestion()"> Envoyer la question</button>
                    <div class="col-2"></div>
                    <button class="col-4 btn btn-secondary" @click="$emit('close')"> Annuler</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import { addNotification, addQuestion } from '@/data/apiThemis'
import store from '@/store'
import { useToast } from 'vue-toastification'

const toast = useToast()
    export default{
        name: 'addQuestionFaq', 
        props:['categories'], 
        data(){
            return{
                category : '',
                title : '', 
                question : ''
            }
        },
        mounted(){
            
        },
        methods:{
            async addQuestion(){
                var payload={
                    "categories" : this.category, 
                    "titre" : this.title, 
                    "question" : this.question, 
                    "isValidated": 'false',
                    "isAnswered": 'false',
                    "userId": store.state.user.Id,
                    // "reponse" : ''
                }
                // console.log(payload)
                const result  = await addQuestion(payload)
                var payloadNotif = {
                    "description": "Nouvelle question insérée : "+this.question.substring(0,50) + "...",
                    "isActive": true,
                    "user": store.state.user.Id,
                    "question": result.id
                }
                console.log(payload)
                await addNotification(payloadNotif)
                toast.success("Question enregistrée")
                this.$emit('close')
            }
        }
    }
</script>