<template>
  <button type="button" class="backDetails btn btn-light btn-circle btn-lg"  @click="backButton"><img src="@/assets/btn_retour.png" style="margin-top: -8px; margin-left:-15px;"/></button>
  <!-- <button type="button" class="backDetails btn btn-light btn-circle btn-lg" @click="backButton">&laquo;</button> -->
</template>

<script>
export default {
  name: "BackButton",
  methods:{
    backButton(){
      console.log("BACK", window.history)
      window.history.back()
    }
  }
};
</script>

<style>
.backDetails {
  width: 28px;
  height: 28px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  display: inline;
  float: left;
  margin: 8px 24px;
}
.backDetails {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  background-color: #61003E;
  color: white;
}
</style>
