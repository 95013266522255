<template>

    <img v-if="!user.profile.hr && !showConversation" @click="showDiscussion" :src="logo" class="logo" >
    <div v-if="user.profile.hr && !showConversation && discussion && discussion.user" class="invitation">
        <h1>DISCUSSION D'URGENCE</h1>
        <h2>-</h2>
        <h2>Demande d'assistance ({{ discussion.user.first_name + ' ' + discussion.user.last_name }})</h2>
        <button class="btn btn-danger" @click="showDiscussion" >REJOINDRE</button>
    </div>
    <div v-if="showConversation" class="conversation">
        <div class="detail">
            <div class="name">
                <img v-if="showConversation" :src="logo" class="profilePicture" >
            </div>
            <h3>Discussion d'urgence</h3>
            <div class="exit" @click="hideDiscussion"><i class="fa-solid fa-xmark"></i></div>
        </div>
        <div v-if="waiting" class="await">
        </div>
        <div v-else id="messages" class="messages">
            <div class="top" v-if="next" @click.self="fetch_next">Messages plus ancien</div>
            <div v-for="message in messages" :key="message.id" class="messageContainer">
                <p class="message" :class="{'messageOther':user.Id!==message.user}">{{ message.content }}</p>
            </div>
            <div class="top" v-if="previous" @click.self="fetch_previous">Messages plus recent</div>
        </div>
        <div class="form">
            <textarea  class="input" v-model="newMessage" rows="3"></textarea>
            <div class="send" @click="sendMessage"><i class="fa-regular fa-paper-plane"></i></div>
        </div>
    </div>
</template>
<script>
import data from '@/data/data';
import store from '@/store';
import { useToast } from 'vue-toastification';
const toast = useToast();
    export default {
        name: 'ChatroomView',
        data(){
            return {
                logo: require('@/assets/urgency.png'),
                showConversation: false,
                discussion: null,
                user:store.state.user,
                messages: null,
                newMessage: '',
                firstTime: true,
                next: null,
                previous: null,
                messagesSize: null,
                waiting: true,
                sendNewMessage: false,
                owner: null,
                interval: null
            }
        },
        async mounted(){
            this.fetchDiscussion()
            this.hideDiscussion()
        },
        updated(){
            const container = document.getElementById('messages');
            if ((this.firstTime && container) || this.sendNewMessage) {
                container.scrollTop = container.scrollHeight;
                this.firstTime = false;
                this.sendNewMessage = false
            }
        },
        methods: {
            showDiscussion(){
                this.discussion.id && this.fetch_latest()
                this.showConversation=true;
                clearInterval(this.interval);
                this.interval = null;
            },
            hideDiscussion(){
                this.showConversation=false;
                this.firstTime = true;
                if (this.user.profile.hr){
                    this.discussion = null
                    this.interval = setInterval(this.fetchDiscussion, 1000)
                }
            },
            async fetchDiscussion(){
                const url = data.baseUrl + 'chat/discussions' + (this.user.profile.hr?'':'/' + this.user.Id)
                const discussionResponse = await fetch(url);
                if (!discussionResponse.ok){
                    toast.error("Erreur lors des chargements de votre discussion.")
                    this.hideDiscussion()
                    return
                }
                this.discussion = await discussionResponse.json();
            },
            fetch_latest(){
                this.fetch_messages(data.baseUrl + 'chat/messages/' + this.discussion.id + '/')
            },
            async sendMessage(){
                const message = {
                    user: this.user.Id,
                    content: this.newMessage,
                    discussion: this.discussion.id,
                };
                this.newMessage = ''
                const payload = {
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(message),
                    method: 'POST',
                };
                const response = await fetch(data.baseUrl + 'chat/messages/' + this.discussion.id + '/', payload);
                if ((response.ok) && (!this.previous)){
                    this.sendNewMessage = true;
                    const latestMessage = await response.json();
                    this.messages.push(latestMessage)
                }
            },
            async fetch_messages(url){
                console.log('URL IN FETCH MESSAGES : ', url)
                if (url){
                    this.waiting=true
                    const messagesResponse = await fetch(url)
                    if(!messagesResponse.ok){
                        toast.error("Erreur lors des chargements de votre discussion.")
                        this.hideDiscussion()
                        return
                    }
                    const fetched_messages = await messagesResponse.json()
                    this.next = fetched_messages.next
                    this.previous = fetched_messages.previous
                    this.messages = fetched_messages.results.reverse()
                    this.waiting=false
                }
            },
            async fetch_next(){
                if(this.next){
                    this.firstTime = true
                }
                this.fetch_messages(this.next)
            },
            async fetch_previous(){
                this.fetch_messages(this.previous)
            }
        }
    }
</script>
<style scoped>
    .invitation{
        width: 600px;
        height: 350px;
        position: fixed;
        background-color: #ffafaf;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 15px 15px rgb(255, 43, 43);
    }
    .invitation>h1{
        font-weight: 900;
        color: #000;
        font-size: 2rem;
    }
    .invitation>button{
        margin-top: 20px;
        padding: 20px 50px;
        font-size: 1.7rem;
        font-weight: 600;
    }
    .conversation{
        position: fixed;
        bottom: 0;
        right: 80px;
        z-index: 2;
        width: 400px;
        height: 500px;
        background-color: #eee;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        /* box-shadow:  0 0  5px 2px #ffffff70; */
        display: flex;
    }
    .logo{
        position: fixed;
        bottom: 50px;
        left: 50px;
        width: 130px;
        height: 130px;
    }
    .exit{
        color: #ec2525;
        font-size: 2rem;
    }
    .logo:hover, .exit:hover{
        transform: scale(1.1);
    }
    .logo:active, .exit:active{
        opacity: .5;
    }
    .detail{
        background-color: #fff;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:  10px;
        box-shadow: 0 2px 2px #ccc;
        position: fixed;
        width: inherit;
    }
    .name{
        display: flex;
        gap: 10px;
        align-items: stretch;
    }
    .name * {
        margin: 0
    }
    .profilePicture{
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }
    .form{
        position: absolute;
        height: 100px;
        width: 100%;
        bottom: 0;
        background-color: #fff;
        display: flex;
        box-shadow: 0 -2px 2px #ccc;
        padding:  10px;
        align-items: flex-start;
        gap: 20px;
    }
    .send{
        color: #071ee9;
        font-size: 2rem;
    }
    .input{
        resize: none;
        flex-grow: 1;
        background-color: #eee;
        border-radius: 5px;
        border-color: #000;
        padding: 0 10px;
    }
    .input:hover{
        background-color: #ccc;
    }
    .input:focus{
        background-color: #eee;
        border: 2px solid #4355f3;
    }
    @keyframes waiting {
        0%   { background: #f8c6c6; }
        50%  { background: #e0c5c5; }
        100%  { background: #e0c5c5; }
    }
    .await{
        animation: waiting 1s infinite linear;
        width: 100%;
        height: 100%;
    }
    .messages{
        padding: 15px;
        position: absolute;
        top: 50px;
        height: 350px;
        width: 100%;
        overflow: auto;flex-grow: 1;
        
    }
    .messageContainer{
        display: flex;
        align-items: flex-end;
    }
    .message{
        color: #fff;
        background-color: #200dc5;
        max-width: 70%;
        padding: 5px 10px;
        border-radius: 15px;
        text-align: start;
        clear: both;
        display: inline-block;
        margin-left: auto; 
        margin-right: 0;
    }
    .messageOther{
        color: #000;
        margin-left: 0; 
        margin-right: auto;
        background-color: #a4a2b8;
    }
    .top, .bottom{
        background-color: #eb9292;
        border: 1px solid rgb(184, 103, 103);
        padding: 5px 20px;
        color: #fff;
        margin-bottom: 10px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 1rem;
    }
    .bottom{
        position: absolute;
        bottom: 0;
    }
</style>