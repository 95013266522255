<template>
    <div >
    <div class="row pl-5 pr-5 pt-3 align-items-center" >
        <div class="col-md-1"></div>
        <div class="col-md-4">
            <img :src="require('@/assets/'+app+'/Profil.png')" />
        </div>
        <div class="col-md-5 card p-5 cardProfile">
            
            <b class="textBgThotea">{{ user.Firstname }} {{ user.Lastname }}</b>
            <br>
            <p class="textTitle">Profil technique : {{ job.title }} </p>
            <p class="textTitle">Nationalité : {{ user.profile.nationality }} </p>
            <p class="textTitle">Adresse : {{ user.profile.street_name }} {{ user.profile.city }} </p>
            <p class="textTitle">Mail : {{ user.Email }}</p>
            <p class="textTitle">No téléphone : {{ user.profile.mobile_num }}</p>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>
</template>
<script>
export default{
    name: 'profileComponent', 
    props:{
        user : Object, 
        job: Object, 
        app : String
    }
}
</script>