<template>
    <div id="thotea">
    <Header :user="user" page="candidat" logo="thotea"/>
   
    <div class="row" > 
        <back-button></back-button>
    <br>
        <div class="col-md-12 pb-2">
                <h2 class="textTitle pl-5" >Les Candidats</h2>
            <div class="row pr-5 pl-5 mr-2 ml-2  align-items-center">
                <button class="btn btn-primary col-md-2 float-left"><router-link to="candidates/add" class="text-white">+ Ajout candidat</router-link></button>
                <div class="col-md-8 "></div>
                <div class=" col-md-2 input-group   float-right ">
                    <input class="form-control inputSearch " v-model="search" @keyup="searching" placeholder="Recherche candidat" style="width: 50%;"/>
                    <div class="input-group-append" style="background-color: #ffffff00;">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                </div>
            </div>
            <br>

            <div class="row pr-5 pl-5" style="max-height: 150vh; overflow-y: auto;">
                
                <div class=" col-md-6" v-for="candidate in listCandidate" :key="candidate.id" align="center">
                    <div class="row   p-4 m-1"  style="background-color: #ffffff33; " >
                        <div class="col-md-5 justify-content-center">
                            <img  src="@/assets/thotea/Profil.png" style="width: 230px;float: right;margin-left: 50%;margin-top: 8%;" />
                        </div>
                        <div class="col-md-7">
                            <!-- <i class="fas fa-user" color="rgb(160, 10, 145)"></i> -->
                            <h4 class="text-white">{{candidate.first_name}} {{ candidate.last_name }}</h4>
                            <br>
                            <p class="textTitle">Nationalité : {{ candidate.extension[0].nationality }} </p>
                            <p class="textTitle">Adresse : {{ candidate.extension[0].street_name }} {{ candidate.extension[0].city }} </p>
                            <p class="textTitle">Mail : {{ candidate.email }}</p>
                            <p class="textTitle">No téléphone : {{ candidate.extension[0].mobile_num }}</p>
                            
                            <hr>

                            <div class="row justify-content-center" align="center">
                                <router-link :to="{name: 'voirResultat', params:{'id': candidate.id}}"><button class="btn btn-info ">Voir résultat</button></router-link>&nbsp;
                                <router-link :to="{name: 'assignerCandidat', params:{'id': candidate.id}}"><button type="button" class="btn btn-secondary ">Assigner</button></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>
<script>
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import axios from 'axios';
    import data from '@/data/data';
    import BackButton from '@/components/shared/BackButton.vue';
    export default{
        name:'list-candidate', 
        components:{Header,BackButton},
        data(){
            return{
                listCandidate: [], 
                user: store.state.user,
                search:''
            }
        },
        mounted(){
            axios.get(data.baseUrl+"authentication/api/api/allUsers/")
            .then(response=>{
                this.candidates = response.data
                .filter(item=>{
                    return item.extension[0]!=undefined
                })
                this.listCandidate = this.candidates
            }).catch(error=> console.log(error))
        }, 
        methods:{
            searching(){
                if(this.search=="") this.listCandidate = this.candidates
                else{
                    this.listCandidate = this.candidates.filter(item=>{
                        return item.last_name.toLowerCase().includes(this.search.toLowerCase()) || item.first_name.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
            },
        }
    }


</script>
<style>
.inputSearch{
    background-color: rgba(255, 255, 255, 0);
    border-color: #fab833;
}
.row::-webkit-scrollbar{
    background: transparent;
}

</style>