<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1;">
            <slot/>
            <div class=" pl-5 pr-5 text-white">
                
                <div class="form-group">
                    <select class="form-control" v-model="reason_refus">
                        <option value="1">Question inappropriée</option>
                        <option value="2">Sujet similaire</option>
                    </select>
                </div>
                <div class="form-group" v-show="reason_refus==2">
                    
                    <select class="form-control" v-model="question_similaire">
                        <option value="">Selectionnez la question similaire</option>
                        <option :value="question.question" v-for="question in allQuestionsSimilaire" :key="question.id">
                            {{question.question}}
                        </option>
                    </select>
                </div>
            </div>
            <hr>
            <div class="row pl-5 pr-5">
                <button class="col-md-4 btn btn-primary" @click="refuseQuestion(faq.id)">Continuer</button>
                <div class="col-4"></div>
                <button class=" col-md-4 btn btn-secondary" @click="$emit('close')">Fermer</button>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import {updateFaqQuestion, getFAQ, addNotification} from '@/data/apiThemis'
import { useToast } from 'vue-toastification';
const toast = useToast()
export default{
    name:'popupRefusal', 
    props:['faq'], 
    data(){
        return {
            question_similaire : '', 
            reason_refus : 1, 
            allQuestionsSimilaire : []
        }
    },
    async mounted(){
        this.allQuestionsSimilaire = await getFAQ()
    },
    methods:{
        async refuseQuestion(question_id){
            let reponse = 'Question inappropriée';
            if (this.reason_refus == '2') {
                reponse = "Sujet similaire, veuillez-vous référer à l'article ID: " + this.question_similaire;
            }
            var payload = {
                "isDeleted": true,
                "isValidated": true,
                "isAnswered": true,
                "reponse": reponse,
                "userId": store.state.user.Id,
            }
            console.log(payload)
             await updateFaqQuestion(question_id, payload)
            var payloadNotif = {
                    "description": "La question avec l'identifiant: "+question_id +" a été refusée",
                    "isActive": true,
                    "user": store.state.user.Id,
                    "question": question_id
                }
                console.log(payload)
                await addNotification(payloadNotif)
            toast.success('Question refusée')
            this.$emit('close')
        }, 
    }
}
</script>