<template>
    
    <Header :user="user" logo="themis" page="faq" />
    
    <div class="row p-5">
        <div class="col-md-4 p-3">
            <h2 class="text-white">FAQ</h2>
            <hr>
            <ul class="list-group">
                
                <li id="tovalid" class="btn btn-outline-info m-3 p-3" @click="showQuestionsToValid()">
                    Questions en attente de validation
                </li>
                <li id="toanswer"  class="btn btn-outline-info m-3 p-3" @click="showQuestionsToAnswer()">
                    Questions en attente de réponse
                </li>
            </ul>
        </div>
        <div class="col-md-8 p-3">
            
            <div class="liste p-2" style="max-height: 75vh; overflow-y: auto;">
                <div class="card  m-3" v-for="faq in questions.sort((a,b)=> new Date(b.date) - new Date(a.date))" :key="faq.id" v-show="faq.question!==''" style="background-color: rgba(255, 255, 255, 0);border: 1px solid white;">
                    <div class="card-header" style="background-color: rgba(255, 255, 255, 0.5);">
                        <p class="mt-2"><b>Catégorie : </b>{{ faq.categories }}</p>
                    </div>
                    <b class="text-white mt-3 ml-5 mr-5"><i class="fa-regular fa-comments" style="font-size: larger;"></i>  {{ faq.question}}</b>
                    <hr>
                    
                    <div class="row pb-4 mt-3 ml-5 mr-5" v-show="toValid">
                        <button class="col-md-4 btn btn-outline-success" @click="accept(faq)">Accepter</button>
                        <div class="col-md-4"></div>
                        <button class="col-md-4 btn btn-outline-danger float-right"  @click="refuse(faq)">Refuser</button>
                    </div>
                    <div class=" pb-4 mt-3 ml-5 mr-5" align="center" v-show="toAnswer">
                        <button class=" btn btn-outline-success" @click="accept(faq)">Ajouter reponse</button>
                        
                        
                    </div>
                </div>
            </div>
        </div>
        <RefusalQuestion v-show="refuseQuestion" :faq="faq"  @close="refuseQuestion=false">
            <button type="button" class="btn  float-right text-white" @click="refuseQuestion=false" >x</button>
            <h3 class="text-white">Motif du refus</h3>
                <br>
        </RefusalQuestion>
        <AcceptanceQuestion v-show="acceptQuestion" :showDialog="false" :faq="faq" >
            <button type="button" class="btn  float-right text-white" @click="acceptQuestion=false" >x</button>
            <h3 class="text-white">Valider question</h3>
                <br>
        </AcceptanceQuestion>
    </div>
    <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import {getFAQTitle, getAllQuestionFaq} from '@/data/apiThemis'

import RefusalQuestion from '../popups/RefusalQuestion.vue';
import AcceptanceQuestion from '../popups/AcceptanceQuestion.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';

 export default {
        name: 'ThemisFAQGest',
        components: { Header, RefusalQuestion, AcceptanceQuestion, Chatroom }, 
        data(){
            return{
                user: store.state.user,
                titles : [], 
                toValid : true, 
                questionsToValid : [], 
                allQuestions : [], 
                questions : [], 
                refuseQuestion :false, 
                acceptQuestion : false,
                faq : {}, toAnswer : false
            }
        }, 
        async mounted(){
            this.titles = await getFAQTitle()
            this.allQuestions = await getAllQuestionFaq()
            this.questions = this.allQuestions.filter(item=>{
                return !item.isValidated
            })
            if(document.getElementById('tovalid'))
                document.getElementById('tovalid').classList.add('active')
        },
        methods:{
            close(){
                this.showPopupQuestion = false
            }, 
            showQuestionsToValid(){
                this.toValid = true
                this.toAnswer = false
                document.getElementById('tovalid').classList.add('active')
                document.getElementById('toanswer').classList.remove('active')
                this.questions = this.allQuestions.filter(item=>{
                    return !item.isValidated
                })
            }, 
            showQuestionsToAnswer(){
                this.toAnswer = true
                document.getElementById('toanswer').classList.add('active')
                document.getElementById('tovalid').classList.remove('active')
                this.questions = this.allQuestions.filter(item=>{
                    return !item.isAnswered
                })
                this.toValid = false
            }, 
            refuse(faq){
                this.faq = faq
                this.refuseQuestion = true
            },
            accept(faq){
                this.faq = faq
                this.acceptQuestion = true
            },
           
               
        }
    }
</script>