<template>
    <div class="app" id="ness">
      <Header :user="user" page="search" logo="ness"/>
      <div class="row">
      <back-button />
      <br>
    </div>
    <div class="row ">
        <div class="col-4"></div>
        <input class="col-4 form-control fieldLogin pl-5 pr-5" placeholder="Nom, Prénom ..." v-model="search" style=""/>
        <div class="col-4"></div>
    </div>
    <div class="row " :class="showRequirements? 'mt-0' : 'mt-5'">
        <div class="col-md-2"></div>
        <div class="col-md-3 mt-5"><button class="btn btn-lg " style="background-color: white;width:100%;color: gold;border-radius: 5% / 50%; font-weight: bold;" @click="init()"><i class="fa-solid fa-rotate-right"></i> REINITIALISER </button></div>
        <div class="col-md-2"></div>
        <div class="col-md-3 mt-5"><button class="btn btn-lg " style="background-color: white;width:100%;color: gold;border-radius: 5% / 50%; font-weight: bold" @click="showRequirements=!showRequirements"><i class="fa-solid fa-crosshairs"></i> CIBLER LA RECHERCHE </button></div>
        <div class="col-md-2"></div>
    </div>
    <div v-show="showRequirements">
        <div class="row pr-5 pl-5 pt-5 pb-2">
            <div class="col-md-6 p-3">
                <div class=" card pr-4 pl-4 pb-4 pt-3 text-white" style="min-height:22vh;max-height: 22vh;overflow-y: auto;background-color: #ffffff00;border: 1px solid goldenrod">
                    <h3 class="textTitle">Type de réseau</h3>
                    <hr>
                    <div class="row">
                        <div class="col-md-3 p-2" v-for="type in typeReseaux"  :key="type.id">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" :id="type.id" @click="addreseaux(type.id)">
                                <label class="form-check-label" :for="type.id">{{type.libelle}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  p-3">
                <div class=" card p-4 text-white" style="max-height: 22vh;overflow-y: auto; background-color: #ffffff00;border: 1px solid goldenrod">
                    <h3 class="textTitle">Pays</h3>
                    <input class=" form-control pl-5 pr-5 fieldLogin" v-model="searchCountry" style="" placeholder="Recherche pays"/>
                    <div class="row">
                        <div class="col-md-4 p-2" v-for="item in filteredCountry()"  :key="item.id">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" :id="item.id" @click="country=item.id" >
                                <label class="form-check-label" :for="item.id">{{item.nom}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="col-md-6  p-3">
                <div class=" card p-4" style="height: 20vh; background-color: #ffffff00;border: 1px solid goldenrod;">
                    <h3 class="textTitle">Secteur d'activités</h3>
                    <hr>
                    <select class="form-control fieldLogin" v-model="secteur">
                        <option value="-1">Sélectionnez un secteur d'activité</option>
                        <option v-for="secteur in secteurs" :key="secteur.id" :value="secteur.id" >
                            {{ secteur.libelle }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6  p-3">
                <div class=" card p-4" style="height: 20vh; background-color: #ffffff00;border: 1px solid goldenrod">
                    <h3 class="textTitle">Mot clé</h3>
                    <hr>
                    <textarea class="form-control fieldLogin" v-model="autre" placeholder="Saisissez un mot clé"></textarea>
                </div>
            </div>
            
        </div>
    </div>
    <div class="row mb-2" :class="showRequirements? 'mt-1' : 'mt-5'">
        <div class="col-md-2"></div>
        <div class="col-md-3"></div>
        <div class="col-md-2 mb-5"><button class="btn btn-lg btn-warning" style="width:100%;color:#FFF;border-radius: 5% / 50%;font-weight: bold;" 
             @click="searchCandidate()" :disabled="search==''"><i class="fa-solid fa-magnifying-glass" style="color: white;"></i> RECHERCHER </button>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-2"></div>
    </div>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
import store from '@/store';
import { getIdHistory, typeReseaux, getCountries, getSecteur } from '@/data/apiNess';
import Chatroom from '@/components/chatroom/Chatroom.vue';
  export default {
    name: "searchNess",
    props: {},
  
    components: {
    BackButton,
    Header,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user, 
        search : '', 
        requirements : [], 
        showRequirements : false, 
        typeReseaux : [], 
        countries : [], 
        secteurs : [],
        searchCountry : '', 
        country:-1, 
        secteur : -1, 
        autre : '', 
        reseaux : []

      };
    },
    async mounted(){
       this.typeReseaux = await typeReseaux()
       this.countries = await getCountries()
       this.secteurs = await getSecteur()
    },
    methods:{
        async searchCandidate(){
            const name = this.search.split(' ')
            let criteres = {};
            console.log(this.country)
            if (this.country!=-1) {
                criteres.pays = this.country;
            }
            if (this.secteur!=-1) {
                criteres.secteur = this.secteur;
            }
            if (this.autre !=='') {
                criteres.autre = this.autre;
            }
            if (this.reseaux.length>0) {
                criteres.reseau = this.reseaux;
            }
            console.log(criteres)
            var payload = {
                "nom" : name[0],
                "prenom" : name[1]? name[1]: '',
                "user" : this.user.Id, 
            }
            if(criteres!={}){
                payload.criteres = criteres
            }
            console.log(criteres)
            const histo = await getIdHistory(payload)
            this.$router.push('/ness/histories/'+histo.historique_id)
        },
        filteredCountry(){
            if(this.searchCountry!=''){
                return this.countries.filter(item=>{
                    return item.nom.toLowerCase().includes(this.searchCountry.toLowerCase())
                })
            }else return this.countries
        }, 
        addreseaux(id){
            const index = this.reseaux.findIndex(item=> {
                return item == id
            })
            if(index==-1)
                this.reseaux.push(id)
            else this.reseaux.splice(index, 1)
        }, 
        init(){
            this.search = ''
            this.country = -1;
            this.secteur = -1;
            this.autre = '';
            this.reseaux = []
        }
    }
  
  };
  </script>
<style>
    .card::-webkit-scrollbar{
        color: transparent;
    }
    input::placeholder{
        color: white !important;
    }
    textarea::placeholder{
        color: white !important;
    }
</style>