<template>
  <div class="app1 app">
    <div class="rowHeader row">
      <h1 class="text-white">RESULTATS </h1>
    </div>
    
    <div class="flexbox1 d-flex justify-content-center"  v-if="item ">
      <div class="containerQuestionnaire container-sm p-4" align="center">
        <div class="row1resultats" >
          <span class="spanResult1"> Questionnaire : {{ item.name }}</span>
        </div>
        <div class="row rowcols">
          <div class="col-sm-3"></div>
          <div class="colProgress col-sm-6">
            <!-- <p style="font-weight: bold; font-size: 22px">Test{{ list.name }}</p> -->
            <div class="progressResultRDQ progress">
              <div
                class="progress-bar progress-bar-striped pbTest" 
                role="progressbar"
                aria-valuenow="10"
                aria-valuemin="0"
                
                :style="'width:' + Math.round(item.results) + '%'"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class=" col-sm-1 textTitle "  >
            <p class="mt-4" style=" margin: 8px;"  >{{(parseFloat(item.results)).toFixed(2) }}%</p>
          </div>
          <div class="col-sm-2"></div>
          <div class="col01 col-sm-6 text-white" style="background-color:  rgba(230, 230, 250, 0);">
            <p class=" float-right" style="font-size: 25px; margin: 8px;">Temps de Réponses</p>
          </div>
          <div class="col01 col-sm-3 " >
            <p style="font-size: 25px; margin: 5px;">{{ item.timeRemaining }}</p>
          </div>
          <div class="col-sm-3"></div>
          <div class="col01 col-sm-6 text-white" style="background-color: rgba(230, 230, 250, 0)">
            <p class=" float-right" style="font-size: 25px; margin: 8px;">Niveau 1</p>
          </div>
          <div class="col01 col-sm-3">
            <p style="font-size: 25px; margin: 5px;">{{ item.levelOne }} / {{ item.countLvl1 }}</p>
          </div>
          <div class="col-sm-3"></div>
          <div class="col01 col-sm-6 text-white "  style="background-color:  rgba(230, 230, 250, 0)">
            <p class=" float-right" style="font-size: 25px; margin: 8px;">Niveau 2</p>
          </div>
          
          <div class="col01 col-sm-3">
            <p style="font-size: 25px; margin: 5px;">{{ item.levelTwo }} / {{ item.countLvl2 }}</p>
          </div>
          <div class="col-sm-3"></div>
          <div class="col01 col-sm-6 text-white"  style="background-color: rgba(230, 230, 250, 0);">
            <p class=" float-right" style="font-size: 25px; margin: 8px;">Niveau 3</p>
          </div>
          <div class="col01 col-sm-3 ">
            <p style="font-size: 25px; margin: 5px;">{{ item.levelThree }} / {{ item.countLvl3 }}</p>
          </div>
          <div class="col-sm-3"></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultatsDuQuestionnaireBox",
  props: {
    
    item : Object,
  },
};
</script>

<style>
.containerQuestionnaire {
  background-color: rgba(255, 255, 255, 0);
  padding: 16px;
  border-radius: 45px;
}

.flexbox1 {
  width: 100%;
  margin: 16px 16px 16px 0;
}

.colProgress{
  margin-top: 10px;
  justify-content: center;
  width: 100%;
  /* border-radius: 45px; */
  background-color: rgba(255, 255, 255, 0);
  /* justify-content: center;
  align-items: center; */
  padding: 8px;
}

.row1resultats {
  justify-content: center;
  padding: 10px;
  margin: auto;
  background-color: #FFF;
  border-radius: 4px;
  margin: 4px;
  width: 65%;
}

/* .pResult {
  display: table-cell;
  vertical-align: middle;
  font-size: 26px;
} */

.spanResult1 {
  font-weight: bold;
  font-size: 28px;
}

.progressResultRDQ{
  width: 100%;
  height: 100%;
  /* border-radius: 45px; */
  /* background-color: cornflowerblue; */
  background-color: rgba(255, 255, 255, 0.263);
  border : 2px solid #c89a1d;
}

.rowca1 {
  justify-content: center;
  margin: 16px;
  /* background-color: yellow; */

}

.rowcols{
 padding: 16px;
}

.colca1 {
  justify-content: center;
  /* margin: 8px; */
  /* background-color: yellow; */

}

/* .row01 {
  /* margin-top: 10px;
  justify-content: center;
  background-color: whitesmoke;
  width: 100%;
  border-radius: 45px;
  /* margin: 5px; 
} */

.col01 {
  margin-top: 10px;
  justify-content: center;
  background-color: white;
  width: 100%;
  /* border-radius: 45px; */
  /* margin: 5px;  */
} 

.rowHeader {
  width: 100%;
  margin-top: 48px;
  justify-content: center;
}

.headerQuestionnaire {
  font-size: 72px;
  font-weight: bold;
}

.app1{
  /* background-color: whitesmoke; */
  margin-top: 72px;
  /* height: 460px; */
}
</style>
