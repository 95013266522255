<template>
    <div id="thotea">
    <Header :user="user" page="questionnaire" logo="thotea"/>
    <div class="" >
        <!-- <button class="thoteaBg btn rounded-circle float-left m-2" @click="back()"><i class="fas fa-arrow-left" color="white"></i></button> -->
        <back-button/>
        <div class="p-5 ">
            <div class="p-5 pl-5 pr-5 " >
            <h1 class="textTitle">Modification questionnaire</h1>
            <hr>
            <form >
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="title">Titre</label>
                    <input type="text" class="form-control fieldLogin" v-model="title" id="title"/>
                </div>
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="categorie">Catégories</label>
                    <select class="form-control pl-4 pr-4 fieldLogin"  v-model="categorie">
                        <option value="">Sélectionnez une catégorie</option>
                        <option v-for="categorie in categories" :key="categorie.id" :value="categorie.id" >{{categorie.title}} </option>
                    </select>
                    
                </div>
                <div class="form-group pl-5 pr-5">
                    <label class="float-left text-white" for="job">Profil</label>
                    <select class="form-control fieldLogin" v-model="job">
                                <option value="">Sélectionnez un profil</option>
                                <option :value="item.id" v-for="item in jobs" :key="item.id">{{ item.title }}</option>
                    </select>
                </div>
                
                
            </form>
            </div>
            <br>
            <!-- <button class="btn btn-secondary" @click="togglePopup()">Fermer</button> -->
            <div align="center">
                <button class="btn btn-warning text-white btn-lg btn-block  ml-1" style="width: 50%;" @click="editQuestionnaire()" :disabled="!isValidFormQuestion">Enregistrer</button>
            </div>
        </div>
    </div>  
    <Chatroom />
</div>
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
import data from '@/data/data';
import functions from '@/data/function';
import store from '@/store/index'
import axios from 'axios';
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';

export default {
        name: 'editQuestionnaire',
        props: ['togglePopup'],
        components:{Header, BackButton}, 
        data(){
            return{
                user : store.state.user,
                job : '', 
                categorie : '', 
                title : '',
                jobs:[],
                categories : [],

                question : '',
                responses : '',
                correctAnswer : '',
                difficulty : 1, 
                showAddQuestionFile : false,
                idQuestionnaire: this.$route.params.id,
                questionnaire:{}
            }
        },
        mounted(){
            if(this.user.profile.user_rights_id<=2 ) this.$router.push("/")
            axios.get(data.baseUrl+"authentication/api/api/jobs")
            .then(response=>{
                this.jobs = response.data
            }).catch(error=> console.log(error))
           axios.get(data.baseUrl+"thotea/api/categories")
            .then(response=>{
                this.categories = response.data
                
            }).catch(error=> console.log(error))
            axios.get(data.baseUrl+"thotea/api/themes/"+this.idQuestionnaire)
                .then(response=>{
                    const questionnaire = response.data
                    this.title = questionnaire.Title
                    this.job = questionnaire.linkedJob[0]? questionnaire.linkedJob[0] : ''
                    this.categorie = questionnaire.category
                }).catch(error=> console.log(error))
        },
        methods:{
            isValidFormQuestion(){
                return (this.job!='' && this.categorie!='' && this.title!='')
            },  
            
            editQuestionnaire(){
                var newQuestionnaire = {
                    "id": parseInt(this.idQuestionnaire),
                    "Title" : this.title,
                    "linkedJob" : [this.job], 
                    "category" : this.categorie
                }
                functions.udpateQuestionnaire(newQuestionnaire)
            },
            back(){
                functions.goBack()
            }
        }
    }
</script>
<style  scoped>
.paddingAdd{
    padding: 5%;
}
</style>