import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from '@fortawesome/fontawesome'
import VueSimpleAlert from "vue3-simple-alert";


import "vue-toastification/dist/index.css";



library.add(fas);
const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};  

const app = createApp(App)
    app.use(store)
    app.use(Toast, options ,VueSimpleAlert)
    app.use(router, axios)
    app.component('faIcon', FontAwesomeIcon)
    app.mount('#app')
