<template>
    <nav class="navbar thoteaBg">
        <a class="navbar-brand text-white "  href="#">
            <a href="/home"><img class="float-left"  src="@/assets/Logo_BULMA.png" :style="logo=='themis'? 'width:7%!important' : 'width:10%'"/></a> 
            <a :href="linkDashboard" ><img class="ml-5 float-left"  :src="require('@/assets/logos/'+logo+'.png')" :style= "logo=='themis'?'width:5%' : 'width:7%'" /></a>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="!showCollab"><router-link :class="page==='test'? 'activeSpan' : 'text-white'" to="/" >Tests</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="showCollab" ><router-link :class="page==='candidat'? 'activeSpan' : 'text-white'" to="/candidates" >Candidats</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='thotea'" v-show="showCollab"><router-link :class="page==='questionnaire'? 'activeSpan' : 'text-white'" to="/listQuestionnaire">Questionnaires</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='thotea'" ><router-link :class="page==='resultat'? 'activeSpan' : 'text-white'" to="/ResultPage">Résultats</router-link></span>

                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="!showCollab"><router-link :class="page==='search'? 'activeSpan' : 'text-white'" to="/ness/search" >Recherche</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="!showCollab"><router-link :class="page==='histories'? 'activeSpan' : 'text-white'" to="/ness/histories" >Historique</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='ness'" v-show="!showCollab" ><router-link :class="page==='reseaux'? 'activeSpan' : 'text-white'" to="/ness/networks">Réseaux</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="showCollab" ><router-link :class="page==='requests'? 'activeSpan' : 'text-white'" to="/ness/requests" >Nouvelles demandes <span class="badge">{{count}}</span></router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='ness'" v-show="showCollab"><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/ness/users" >Utilisateurs</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='ness'" v-show="showCollab"><router-link :class="page==='reseaux'? 'activeSpan' : 'text-white'" to="/ness/gest-networks">Réseaux</router-link></span>

                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='codeTravail'? 'activeSpan' : 'text-white'" to="/themis/laborcode" >Code du travail</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='agreements'? 'activeSpan' : 'text-white'" to="/themis/collective-agreements" >Conventions collectives</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;" ><router-link :class="page==='entreprise'? 'activeSpan' : 'text-white'" to="/themis/my-company">Mon entreprise</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="!showCollab" style="font-size: large;"><router-link :class="page==='faq'? 'activeSpan' : 'text-white'" to="/themis/faq">FAQ</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/themis/users" >Utilisateurs</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='entreprise'? 'activeSpan' : 'text-white'" to="/themis/my-company">Mon entreprise</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='themis'" v-show="showCollab" style="font-size: large;"><router-link :class="page==='faq'? 'activeSpan' : 'text-white'" to="/themis/faq-gest">FAQ</router-link></span>
                
                <span class="ml-5 float-left pt-3" v-if="logo=='pythie'" v-show="!showCollab"><router-link :class="page==='user-profile'? 'activeSpan' : 'text-white'" to="/pythie/user-profile" >Profil</router-link></span>
                <span class="ml-5 float-left pt-3" v-if="logo=='pythie'" v-show="!showCollab"><router-link :class="page==='plan'? 'activeSpan' : 'text-white'" to="/pythie/career-plan" >Plan de carrière</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='pythie'" v-show="!showCollab" ><router-link :class="page==='suivi'? 'activeSpan' : 'text-white'" to="/pythie/activities-tracking">Suivi d'activité</router-link></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='pythie'" v-show="showCollab" ><router-link :class="page==='users'? 'activeSpan' : 'text-white'" to="/pythie/users">Utilisateurs</router-link></span>
                
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
                <span class="ml-5 float-left pt-3 " v-if="logo=='vger'"  ></span>
            </a>
        <div>
            
            <!-- <a style="cursor: pointer;" @click="logout()" v-if="user && user.Id"  data-toggle="tooltip" data-placement="top" title="Se déconnecter">{{ user.username }}<img src="@/assets/profil_photo.png"/></a> -->
            <div class="dropdown" v-if="user && user.Id">
                <router-link to="/themis/notification" v-show="showCollab" v-if="logo=='themis'" class="mr-3"> <i class="fa-solid fa-bell mt-2" style="font-size: xx-large;"></i><small class="badge" style="background-color: red;margin-left: -4% !important;margin-top: -10px;">{{ notificationCount }}</small></router-link>
            <router-link :to="linkProfile"><span class="btnProfil mr-2 mt-2 text-white">PROFIL</span> </router-link>
                <a class="btn text-white navbar-brand dropdown-toggle" 
                    role="button" data-toggle="dropdown" aria-expanded="false" 
                    @click.prevent="toggleDropdown">
                    {{ user.Username }}
                    <img src="@/assets/profil_photo.png"/>
                </a>

            <div class="dropdown-menu is-right" :style="{display: show?'block':null, left:'auto',
                right:0}" >
                <a  v-if="logo=='themis'" class="dropdown-item" href="/themis/favoris"><i class="fa-solid fa-star" color="yellow"></i> Favoris </a>
                <a class="dropdown-item" :href="linkProfile">Profil</a>
                <a class="dropdown-item" v-if="user.profile.user_rights_id>2" :href="linkDashboard" @click="toggleView" >{{ otherRole }}</a>
                <router-link class="dropdown-item" v-if="user.profile.user_rights_id>2 && logo=='thotea'" :to="{name: 'quizz-management'}">Gestion des questionnaires</router-link>
                <a class="dropdown-item" href="#" @click="logout()">Se déconnecter</a>
            </div>
        </div>
        </div>
    </nav>
    <link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>
</template>
<script>
import store from '@/store'
import {getWaitingNetworks} from '@/data/apiNess'
import {getNotification} from '@/data/apiThemis'
     export default{
        name : 'headerComponent',
        props:{
            user : Object, 
            page : String,
            logo : String
        },
        data(){
            return {
                show: false,
                showCollab : store.state.showCollab, 
                linkDashboard : '', 
                linkProfile : '', 
                reseaux : [], count : 0, 
                notificationCount : 0
            }
        },
        async mounted(){
            // if(this.user==undefined){
            //     this.$router.push("/login")
            // }
            if(this.logo == 'ness'){
                this.linkDashboard = '/ness/dashboard'
                this.linkProfile = '/ness/profile'
                this.reseaux = await getWaitingNetworks()
                this.reseaux.map(item=>{
                    if(item.validation==null){
                        this.count ++
                    }
                })
            }else if(this.logo=='thotea'){
                this.linkDashboard = '/dashboard'
                this.linkProfile = '/profil'
            }
            else{
                this.linkDashboard = '/'+this.logo
                this.linkProfile = '/'+this.logo+'/profile'
            }

            if(this.logo=='themis'){
                const notification = await getNotification()
                const newNotif = notification.filter(item=>{
                    return item.isActive 
                })
                this.notificationCount = newNotif.length
            }
        },
        methods:{
            logout(){
                this.$store.dispatch('logout')
                .then(response=> {
                    console.log(response)
                    this.toggleViewWithBool(false)
                    this.$router.push('/login')
                }).catch(error=>{
                    console.log(error)
                })
            },
            toggleDropdown(){
                this.show = !this.show
            },
            toggleView(){
                this.$store.commit('toggleShowCollab', !this.$store.state.showCollab)
            },
            toggleViewWithBool(bool){
                this.$store.commit('toggleShowCollab', bool)
            }
        },
        computed:{
            otherRole(){
                if(this.logo=='thotea')
                    return this.$store.state.showCollab?"Candidat":"Gestionnaire"
                else 
                    return this.$store.state.showCollab?"Collaborateur":"Gestionnaire"
            }
        }
     }
</script>
<style>
    .float-left{
        float: left;
    }
    .btnProfil{
        
        padding: 10px;
        border-radius: 10% / 60%;
        border: 1px solid ;
        margin-top: 5px!important;
    }
    .activeSpan{
        /* padding: 10px; */
        /* background-color: #fab833; */
        border-radius: 4%;
        color: #fab833!important;
    }
    
</style>