<template>
    <div id="pythie">
        <Header :user="user" logo="pythie" page="plan"/>
        <back-button />
        <br>
        <div class=" pl-5 pr-5 pb-5" align="center">
            <img src="@/assets/pythie/plan_carriere.png" />
            <br>
            <p class="text-white pt-2">Faisons ensemble ton plan de carrière !</p>
            
        </div>
    </div>
    <Chatroom/>
</template>
<script>
import store from '@/store';
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';
    export default {
        name: 'UserProfilePythie',
        components:{Header, BackButton, Chatroom}, 
        data(){
            return {
                user : store.state.user,
            }
        }, 
        mounted(){
           
        },
        methods:{
           
        }
    }
</script>