<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222dd1;">
            <slot/>
            
            <div class="pl-5 pr-5" v-show="!oui">
                <p class="text-white">Voulez-vous répondre à la question immédiatement?</p>
                <br>
                <div class="row">
                    <button class="col-md-6 btn  btn-outline-info" @click="oui=true" >
                        oui
                    </button>
                    <button class="col-md-6 btn btn-outline-info" @click="acceptQuestion(faq.id)">
                        non
                    </button>
                </div>
            </div>
            <div class="pl-5 pr-5" v-show="oui">
            <!-- <form> -->
                <div class="form-group ">
                    <label class="textTitle">Catégorie {{ category }}</label>
                    <select class="form-control fieldLogin" v-model="category">
                        <option value="">Selectionner une catégorie</option>
                        <option :value="category.titre" v-for="category in titles" :key="category.id">
                            {{ category.titre }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="textTitle">Question</label>
                    <textarea class="form-control fieldLogin" disabled v-model="question" style="color: black;"></textarea>
                </div>
                <div class="form-group">
                    <label class="textTitle">Réponse</label>
                    <textarea class="form-control fieldLogin" v-model="answer"></textarea>
                </div>
                <div class="row pr-5 pl-5 pt-3">
                    <button class="col-md-6 btn btn-primary" @click="oui=false;acceptQuestion(faq.id)"> Envoyer la question</button>
                    <div class="col-2"></div>
                    <button class="col-md-4 btn btn-secondary" @click="$emit('close')"> Annuler</button>
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import {updateFaqQuestion, getFAQTitle, addNotification} from '@/data/apiThemis'
import { useToast } from 'vue-toastification';
const toast = useToast()
export default{
    name:'popupAccept', 
    props:['faq', 'showDialog'], 
    data(){
        return {
            oui : false,
            category : '', 
            question : '', 
            answer : '', 
            titles:[], 
        }
    },
    async mounted(){
        this.oui= this.showDialog
        this.titles = await getFAQTitle()
        this.category = this.faq.categories
        this.question = this.faq.question
        console.log(this.category)
    },
    methods:{
        async acceptQuestion(question_id){
                var payload = {
                    "isValidated": true,
                    "userId": store.state.user.Id, 
                }
                if(this.answer!=''){
                    payload.reponse=this.answer
                    payload.isAnswered =  true
                }
                console.log(payload)
                const result  = await updateFaqQuestion(question_id, payload)
                var payloadNotif = {
                    "description": "Nouvelle question validée",
                    "isActive": true,
                    "user": store.state.user.Id,
                    "question": result.id
                }
                console.log(payload)
                await addNotification(payloadNotif)
                toast.success(JSON.stringify(result))
                
                this.$emit('close')
        },
    }
}
</script>