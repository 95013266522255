<template>
    <div id="pythie">
        <Header :user="user" logo="pythie" />
        <back-button></back-button>
        <div class="p-3">         
            <br>
            <Profile :user="user" :job="job" app="pythie"/>
            <br><br>
            <!-- <div class="row justify-content-center " style="margin-left: 25%;">
                <router-link to="/ness/histories/"><button class="btn rounded-circle btn-lg ml-5" style="background-color: white;width: 11vh;height: 11vh;"><i class="fa-solid fa-clock-rotate-left" color="gold" style="font-size: x-large;"></i></button></router-link>
                <router-link to="/ness/search"><button class="btn rounded-circle btn-lg ml-5" style="background-color: white;width: 11vh;height: 11vh;"><i class="fa-solid fa-magnifying-glass" color="gold" style="font-size: x-large;"></i></button></router-link>
                <router-link to="/ness/network-request/"><button class="btn rounded-circle btn-lg ml-5" style="background-color: white;width: 11vh;height: 11vh;"><i class="fa-solid fa-list" color="gold" style="font-size: x-large;"></i></button></router-link>
            </div> -->
        </div>  
            
    </div>
    <Chatroom/>
</template>
<script>

import store from '@/store';
import Header from '@/components/shared/Header.vue';
import Profile from '@/components/shared/Profile.vue';
import BackButton from '@/components/shared/BackButton.vue';
import axios from 'axios';
import data from '@/data/data';
import Chatroom from '@/components/chatroom/Chatroom.vue';
export default {
    name:"profile-pythie",
    components:{ Header, Profile, BackButton, Chatroom },
    data(){
        return {
            user : store.state.user, 
            job : {}
        }
    }, 
    mounted(){
        this.getJob()
    },
    methods:{
        getJob(){
            axios.get(data.baseUrl+'authentication/api/api/jobs/'+this.user.profile.job_id)
            .then(response=>{
                this.job = response.data
                
            }).catch(error=> console.log(error))
        }
    }
}
</script>
<style>
#ness{
    min-height: 100vh;
    background-image: url('@/assets/ness/Background-01.jpg');
    background-size: cover; 
    background-repeat: no-repeat; 
}
</style>