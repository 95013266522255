<template>
    <div id="pythie">
        <Header :user="user" logo="pythie" page="user-profile"/>
        <back-button />
        <br>
        <UserProfile :user="user" ></UserProfile>
    </div>
    <Chatroom/>
</template>
<script>
import store from '@/store';

import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import UserProfile from '@/components/pythie/UserProfile.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';

    export default {
        name: 'UserProfilePythie',
        components:{ UserProfile, Header, BackButton, Chatroom }, 
        data(){
            return {
                user : store.state.user,
            }
        },
    }
</script>