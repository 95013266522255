export function isCorrect(answers, correctAnswers){
    let tabAnswers = answers.split('|||')
    let nbreCorrectAnswer = correctAnswers.split('|||').length
    if(nbreCorrectAnswer==1){
      if(tabAnswers.length==1) {
        if(answers.trim()==correctAnswers.trim())
          return true
      }else{
          return false
      }
    }else{
      let count=0;
      for(let i=0; i<tabAnswers.length;i++){
        if(correctAnswers.includes(tabAnswers[i].trim())){
          count++
        }
      }
      if(count==nbreCorrectAnswer){
          return true
        }
    }
    return false
}