<template>
    <div id="thotea">
        <Header :user="user" page="questionnaire" logo="thotea" />
        
        <!-- <button class="thoteaBg btn rounded-circle float-left m-2" @click="back()"><i class="fas fa-arrow-left" color="white"></i></button> -->
        <div class="p-3 " >
            <back-button />
            <h1 v-if="theme[0]" class="textTitle">Questionnaire {{ theme[0].Title }}   <button class="btn btn-primary" @click="downloadFile()"><i class="fa-solid fa-download"></i></button></h1>
            
            <br>
            <p class="text-white">Nombre total de questions : {{ countAllQuestion }}</p>
            <br>
            
            <div class="case pt-5 pb-5 pl-3 pr-5">
                <button class="btn btn-primary float-right" @click="togglePopup()"> + Ajouter une question</button>
            <div class=" pr-5 pl-5">
                <div class="row pl-5 pr-5 mb-2">
                    <div class="col-md-7">
                        <select class="form-control" id="recruteur" v-model="niveau" @change="changeLevel">
                            <option value="1">Niveau 1</option>
                            <option value="2">Niveau 2</option>
                            <option value="3">Niveau 3</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group">
                            <input class="form-control " v-model="search" @keyup="searching" placeholder="Recherche question"/>
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fas fa-search"></i></span>
                            </div>
                        </div>
                        
                    </div>
                
                <p class="float-right mt-2 text-white"> <br>Nbre de questions : {{questions.length}}</p>
            
                </div>
                
            </div>

            <div class="row pl-5 pr-5 pt-2 ">

                <div class="col-md-4" width="100%" v-for="(question, index) in questions.slice(startRowAcc,startRowAcc+rowsPerPageAcc)" :key="question.id">
                    <div class="card p-2 pt-2 mb-1">
                        <div :style="{'flex-direction': 'row', display: 'flex'}">
                            <div class="col-md-10">
                                {{index+1+startRowAcc}}<b class="pt-2"> - {{question.question}} <span class="float-right"> Niveau {{ question.difficulty }}</span></b>
                            </div>
                            <div class="col-md-2">
                                <span class="btn btn-danger" style="border-radius: 100%;font-size: smaller;" @click="removeQuestion(question)" title="Supprimer cette question">
                                    <i class="fa fa-trash" />
                                </span>
                                <br><br>
                                <span class="btn btn-info" style="border-radius: 100%;font-size: smaller;" @click="editQuestion(question)" title="Modifier cette question">
                                    <i class="fa fa-edit" />
                                </span>
                            </div>
                        </div>
                        <hr>

                        <p  v-for="(resp, index) in question.answers.split('|||')" :key="index">
                            <span :class="question.correctAnswer.split('|||').map(answer => answer.trim()).includes(resp.trim()) ? 'btn-success p-2' : 'p-2'">{{resp}}</span>
                            <!-- <span :class="resp.trim()===question.correctAnswer.trim() ? 'btn-success p-2' : 'p-2'" >{{resp}}</span> -->
                        </p>
                    </div>
                </div>
            </div>
            <div id="page-navigation" v-if="questions.length>rowsPerPageAcc">
                <button class="btn btn-primary text-white" @click=movePage(-1)>Précédent</button>
                {{startRowAcc / rowsPerPageAcc + 1}} page sur {{Math.ceil(questions.length / rowsPerPageAcc)}}
                <button class="btn btn-primary text-white" @click=movePage(1)>Suivant</button>
            </div> 
            </div>
        </div>

        <AddQuestion v-if="popupTrigger" :togglePopup="togglePopup" :idQuestionnaire="idTheme" :theme="theme[0]" :questionsTheme="questions">
            <button type="button" class="btn  float-right text-white" @click="togglePopup()" >x</button>
            <h1 class="textTitle">Ajout question</h1>
            
            <hr>
        </addQuestion>

        <EditQuestion v-if="popupTriggerEdit" :togglePopup="togglePopupEdit" :question="questionToEdit">
            <button type="button" class="btn  float-right text-white" @click="togglePopupEdit()" >x</button>
            <h1 class="textTitle">Modifier question dans {{theme[0].Title}}</h1>
            <hr>
        </EditQuestion>
        
    </div>
    <Chatroom />
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>

<script>
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import axios from 'axios';
    import data from '@/data/data';
    import functions from '@/data/function';
    import AddQuestion from './addQuestion.vue';
    import EditQuestion from './editQuestion.vue'
    import BackButton from '@/components/shared/BackButton.vue';
    import { useToast } from 'vue-toastification';
    import * as XLSX from 'xlsx'
    const toast = useToast();
      export default {
        name: 'listQuestionnaire',
        props: {
         
        },
        components:{ Header, AddQuestion, EditQuestion },
        
        data(){
            return{
                user : store.state.user,
                idTheme : this.$route.params.id,
                questions : [], allQuestions:[],allQuestionslevel:[],
                niveau: 1,
                search:'', 
                countAllQuestion : 0, 
                theme : {},
                startRowAcc: 0,
                rowsPerPageAcc: 12,
                popupTrigger : false, 
                popupTriggerEdit : false,
                questionToEdit : null
            }
        },
        mounted(){
            if(this.user.profile.user_rights_id<=2 ) this.$router.push("/")
                axios.get(data.baseUrl+"thotea/api/getQuestionnaireList/?questionnaire_id="+this.idTheme)
                .then(response=>{
                    this.countAllQuestion = response.data.length
                    this.allQuestions = response.data
                    this.questions = response.data.filter(item=>{
                        return item.difficulty==this.niveau
                    })
                    
                    this.allQuestionslevel = this.questions
                }).catch(error=> console.log(error))
            axios.get(data.baseUrl+"thotea/api/themes")
            .then(response=>{
                 this.theme = response.data.filter(item=>{
                    return item.id==this.idTheme
                 })
            }).catch(error=> console.log(error))
        },
        methods:{
            searching(){
                
                if(this.search=="") this.questions = this.allQuestionslevel
                else{
                    this.startRowAcc = 0
                    const questionFilter = this.questions
                    this.questions = questionFilter.filter(item=>{
                        return (item.question.toLowerCase().includes(this.search.toLowerCase()) )
                    })
                }
            },
            changeLevel(){
                this.startRowAcc = 0,
                this.questions = this.allQuestions.filter(item=>{
                    return item.difficulty==this.niveau
                })
            }, 
            movePage(amount) {
                var newStartRows = this.startRowAcc + (amount * this.rowsPerPageAcc);
                if (newStartRows >= 0 && newStartRows < this.questions.length) {
                    this.startRowAcc = newStartRows;
                }
            },  
            togglePopup(){
                this.popupTrigger=!this.popupTrigger
            },
            togglePopupEdit(){
                this.popupTriggerEdit=!this.popupTriggerEdit
            },

            back(){
                functions.goBack()
            },
            removeQuestion(question){
                axios.delete(data.baseUrl+"thotea/api/getThemeQuestion/"+question.id+"/")
                .then(response => {
                    this.questions = this.questions.filter(item => item !==question)
                    toast.success('Question supprimé : ', JSON.stringify(question) + '\nresponse :  ', JSON.stringify(response));
                })
            },
            editQuestion(question){
                this.togglePopupEdit()
                this.questionToEdit = question
            }, 
            downloadFile(){
                const header = ["Question", "Answers", "Correct Answer", "Difficulty"];
                const doubleArray = this.questions.map(item => [item.question, item.answers, item.correctAnswer, item.difficulty])
                doubleArray.unshift(header)
                const feuille = XLSX.utils.aoa_to_sheet(doubleArray);
                const classeur = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(classeur, feuille, "Feuille1");
                XLSX.writeFile(classeur, this.theme[0].Title+".xlsx");

            }
        },
    }
</script>
<style>
</style>