<template>
    <div id="thotea">
    <Header :user="user" logo="thotea"/>
    <h2 class=" text-center pt-2 mt-3 title"  >Fiche de renseignements </h2>
    <hr/>
    <form class="pl-5 pr-5 " action="" v-if="user && profils">
        <div class="row">
            <div class="col-6 pl-5 pr-5">
                <div class="form-group">
                    <label class="labelForm label" for="name">Nom</label>
                    <input type="text" class="form-control input" id="name"  v-model="user.Lastname" required />
                </div>
                <div class="form-group">
                        <label class="labelForm label" for="firstname">Prénom</label>
                        <input type="text" class="form-control input" id="firstname" v-model="user.Firstname" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="email">Adresse email</label>
                    <input type="email" class="form-control input" id="email" v-model="user.Email" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="nickname">Nickname</label>
                    <input type="text" class="form-control input" id="nickname" v-model="user.Username" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="codePostal">Code postal</label>
                    <input type="number" class="form-control input" id="codePostal" v-model="user.profile.postal_code" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="ville">Ville</label>
                    <input type="text" class="form-control input" id="ville" v-model="user.profile.city" required />
                </div>
            </div>

            <div class="col-6 pl-5 pr-5">
                <div class="form-group">
                    <label class="labelForm label" for="nationality">Nationalité</label>
                    <input type="text" class="form-control input" id="nationality" v-model="user.profile.nationality" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="profil">Profil technique </label>
                    <select class="form-control input" id="profil" v-model="user.profile.job_id" required >
                        <option :value="profil.id" v-for="profil in profils" :key="profil.id">{{profil.title}}</option>
                    </select>
                </div>
                
                <div class="form-group">
                    <label class="labelForm label" for="phoneNumber">Numéro de téléphone</label>
                    <input type="tel" class="form-control input" id="phoneNumber" v-model="user.profile.mobile_num" required/>
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="dob">Date de naissance</label>
                    <input type="date" class="form-control input" id="dob" v-model="user.profile.dob" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="disponibilite">Disponibilité</label>
                    <input type="date" class="form-control input" id="disponibilite" v-model="user.profile.availability" required />
                </div>
                <div class="form-group">
                    <label class="labelForm label" for="salaire">Prétention salariale</label>
                    <input type="text" class="form-control input" id="salaire" v-model="user.profile.salary_expectations" required />
                </div>
            </div>
            
        </div>
        <button type="submit" @click.prevent="updateProfil"  class="btn btn-block btn-success py-3">Valider</button>
    </form>
    <Chatroom />
    </div>
</template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
    import Header from '@/components/shared/Header.vue';
</script>
      <script>
    import axios from 'axios'
    import data from '@/data/data'
    import store from '@/store'
    import { useToast } from "vue-toastification";
    const toast = useToast()
      export default {
  components: { Chatroom },
        name: 'EditProfil',
        mounted(){
            axios.get(data.baseUrl+'authentication/api/api/jobs/')
                .then(response=>{
                    this.profils = response.data
                }).catch(error=> console.log(error))
        },
        data(){
            return {
                profils : [],
                user: store.state.user,
            }
        }, 
        
        methods:{
            async updateProfil(){
                const formUser = this.user;
                try{
                    if ([formUser.Firstname, formUser.Lastname, formUser.Email, formUser.Username].some(value => !value)){
                        console.log('Here');
                        throw new Error();
                    }
                    const user = await (await fetch(data.baseUrl + "authentication/api/api/allUsers/" + formUser.Id +'/')).json()
                    const profile = user.extension[0];
                    profile.dob = formUser.profile.dob
                    profile.availability = formUser.profile.availability
                    profile.job_id = formUser.profile.job_id;
                    profile.city = formUser.profile.city;
                    profile.mobile_num = formUser.profile.mobile_num;
                    profile.nationality = formUser.profile.nationality;
                    profile.postal_code = formUser.profile.postal_code;
                    profile.salary_expectations = formUser.profile.salary_expectations;
                    console.log('BODY : ', profile);
                    user.email = formUser.Email;
                    user.first_name = formUser.Firstname;
                    user.last_name = formUser.Lastname;
                    user.username = formUser.Username
                    
                    const payloadUser = {
                        method: 'PUT',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(user),
                    }
                    const userUpdated = await (fetch(data.baseUrl + "authentication/api/api/allUsers/" + user.id +'/', payloadUser))
                    if (userUpdated.ok){
                        const payload = {
                            method: 'PUT',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(profile),
                        }
                        const profileUpdated = await (fetch(data.baseUrl + "authentication/api/api/userprofile/" + profile.id +'/', payload))
                        if (profileUpdated.ok){
                            store.commit('UPDATE_USER', formUser);
                            toast.success('Mise à jours de profil avec succès')
                        }
                        else{
                            throw new Error();
                        }
                    }
                    else{
                        throw new Error();
                    }
                }
                catch(e){
                    toast.error('Une erreur est survenu lors de la mise à jours de votre profil')
                }
            }
        }
    
      }
      </script>
     <style scoped>
        .title{
            color: #fab833;
            font-weight: 600;
            background-color: #ffffff00;
        }
        .label{
            color: #fab833;
        }
        .input{
            background-color: #9a4c7660;
            color: #fff;
        }
        .input:hover, .input:active, .input:focus{
            background-color: #7e325a60;
        }
        option{
            background-color: hsl(330, 53%, 25%)
        }
        .navbar{
            /*background-image: url('@/assets/fond_site_web.png');*/
            color: white;
        }
        .labelForm label{
            float: left;
        }
        .text-fiche{
            color : white;
            font-weight: bold;
            font-size: x-large;
        }
        .thoteaBg{
            background-color: rgba(255, 255, 255, 0.029);
            align-items: center;
        }
        .bgForm{
            background-color: #fcfbfb74;
        }
    </style>
      
      