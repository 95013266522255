<template>
    <div id="pythie">
        <Header :user="user" logo="pythie" page="suivi"/>
        <back-button />
        
        <ActivitiesUser :user="user" ></ActivitiesUser>
    </div>
    <Chatroom/>
</template>
<script>
import store from '@/store';
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
// import {getSuivi, getMissions} from '@/data/apiPythie'
import ActivitiesUser from '@/components/pythie/ActivitiesUser.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';
    export default {
        name: 'UserProfilePythie',
        components:{ Header, BackButton, ActivitiesUser, Chatroom }, 
        data(){
            return {
                user : store.state.user,
               
            }
        }, 
       
    }
</script>