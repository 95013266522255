<template>
  <div id="thotea">
    <Header :user="user" logo="thotea"/>
    <back-button />
      <ResultatsDuQuestionnaireBox  :item="item" />
      <ButtonAllResults/>
      <Chatroom />
    </div>
</template>
<script setup>
  import Chatroom from "@/components/chatroom/Chatroom.vue";
</script>
<script>
import axios from "axios";

import BackButton from "@/components/shared/BackButton.vue";
import store from "@/store";
import Header from '@/components/shared/Header.vue';
import ResultatsDuQuestionnaireBox from "@/components/ResultatsDuQuestionnaireBox.vue";
import ButtonAllResults from "@/components/ButtonAllResults.vue";
import data from '@/data/data'
export default {
  name: "App",
  components: {
    BackButton,
    Header,
    ResultatsDuQuestionnaireBox,
    ButtonAllResults,
  },

  data() {
    return {
      list: [],
      index: 0,
      item: undefined,
      user: store.state.user,
      showResults:[],
      idQuestionGrade: this.$route.params.id,
    };
  },

  async mounted() {
    await axios.get(
      data.baseUrl + "thotea/api/questiongrade/?userId="+this.user.Id
    ).then((res) => {
      this.list = res.data;
      
      const itemFilter = this.list.filter(item=>{
        return item.id == this.idQuestionGrade
      });
      if(itemFilter.length>0)
        this.item = itemFilter[0]
      
      
    })
    .catch(error=> console.log(error));

  },

  methods: {
    
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
</style>
