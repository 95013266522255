<template>
<Header :user="user" logo="themis" page="" />
    <div class="row">
        <div class="col-2"></div>
        <div class="card col-8   p-5  pl-5 pr-5 text-white" align="center" style="background-color: rgba(255, 255, 255, 0);max-height: 80vh; overflow-y: auto;">
        <ul class="list-group " v-if="notifications.length>0">
            <li class="list-group-item m-2 p-3" v-for="notification in notifications.sort((a,b)=> new Date(b.date) - new Date(a.date))" :key="notification.id" 
                style="border: 1px solid rgba(255, 255, 255, 0.414);background-color: rgba(255, 255, 255, 0);cursor: pointer;" :style="notification.isActive? 'color:gold' : 'color:white'"
                @click="update(notification)" :id="'item'+notification.id">
                <b style="float: right;">{{ formatDate(notification.date, 'DD/MM/YYYY HH:MM:ss')}}</b>  
                              
                <p class="mt-2" style="float: left;">{{ notification.description }}</p>
            </li> 
        </ul>
        <p class="mt-5" v-else>Aucune notifications</p>
    </div>
        <div class="col-2"></div>
    </div>
    
    <Chatroom/>
</template>

<script>
import { getNotification , updateNotif} from '@/data/apiThemis';
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import moment from 'moment';
import Chatroom from '@/components/chatroom/Chatroom.vue';

    export default {
        name : 'NotificationPage',
        components:{
    Header,
    Chatroom
},
        data(){
            return {
                notifications : [], 
                user : store.state.user
            }
        }, 
        async mounted(){
            this.notifications = await getNotification()
            
        }, 
        methods:{
            formatDate(date, format){
                const parsedDate = moment(date, moment.ISO_8601, true);
                return parsedDate.isValid()?  parsedDate.format(format) : ' - '
            },
            async update(notification){
                if(notification.isActive){
                    var payload = {
                        ...notification,
                        "isActive" : false 
                    }
                    await updateNotif(notification.id, payload)
                    document.getElementById('item'+notification.id).style.color = 'white'
                    
                }
                this.$router.push('/themis/faq-gest')
            }
        }
    }

</script>