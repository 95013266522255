<template>
    <div class="pl-5 pr-5">
        
            <div class="row pr-3 pl-3">
                <div class="col-md-2">
                    <img src="@/assets/pythie/Profil.png" style="width: 100%;"/>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-9 p-5 cardTitle" v-if="user">
                    <div class="row">
                        <div class="col-md-4">
                            <b>{{ user.Firstname }} {{ user.Lastname }}</b> , <span class="textTitle">{{getAge()}} ans </span> 
                            <br>
                            <p class="textTitle mt-1">{{ job.title }}</p>
                            <p>{{ user.profile.user_rights_id === 'CANDIDAT'? 'Candidat' : 'Salarié' }}</p>

                            <router-link :to="id? '/pythie/activities-tracking/'+id : '/pythie/activities-tracking'">Suivi d'activité</router-link>
                        </div>
                        <div class="col-md-4 mt-3" >
                            <i class="fa-solid fa-link" color="goldenrod"></i>
                            <b class="textTitle">  Vlinks</b>
                            <br>
                            {{ vlinks }}
                        </div>
                        <div class="col-md-4">
                            Progression
                            <div class="step-indicator" v-if="departement.extension">
                                <div v-for="(step, index) in departement.extension.slice().sort((a, b) => a.scale - b.scale)" :key="index" class="step">
                                    {{ step.title }}
                                </div>
                            </div>
                            <!-- <div class="row" v-if="departement.extension">
                                <div class="col-6" v-for="dep in departement.extension.slice().sort((a, b) => a.scale - b.scale)" :key="dep.id">
                                    <hr style="color: red;">
                                    <br>
                                    {{ dep.title }}
                                </div>
                            </div> -->
                            
                        </div>
                    </div>
                </div>
            
            </div>
            <br>
            <div class="row" style="max-height: 50vh;overflow-y: auto;">
                    <div class="col-md-6 p-3">
                        <div class="card" style="background-color: rgba(255, 255, 255,0.2);border-radius: 4%/10%;">
                            <div class="card-header-py text-white p-2">
                                <b>DONNÉES PERSONNELLES</b>
                            </div>
                            <div class="card-body  p-3" style="background-color: white;padding-right: 20px;padding-left: 20px;">
                                <table style="width: 90%;">
                                    <tr>
                                        <td></td>
                                        <td class="float-right" ><i class="fa-solid fa-location-dot" color="gold"></i> </td>
                                        <td>{{ user.profile.street_name }} {{ user.profile.postal_code }} {{ user.profile.city }}</td>
                                    </tr>
                                    <br>
                                    <tr>
                                        <td></td>
                                        <td class="float-right"><i class="fa-solid fa-cake-candles" color="gold"></i></td>
                                        <td > {{formatDate(user.profile.dob) }}</td>
                                    </tr>
                                    <br>
                                    <tr>
                                        <td></td>
                                        <td class="float-right"><i class="fa-solid fa-phone" color="gold"></i></td>
                                        <td> {{ user.profile.mobile_num }}</td>
                                    </tr>
                                    <br>
                                    <tr>
                                        <td></td>
                                        <td class="float-right"><i class="fa-solid fa-envelope" color="gold"></i></td>
                                        <td>{{ user.Email }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-3">
                        <div class="card " style="background-color: rgba(255, 255, 255,0.2);border-radius: 4%/10%;">
                            <div class="card-header-py text-white p-2">
                                <b>THOTEA</b>
                                <router-link class="float-right" :to="'/voirResultat/'+user.Id"><i class="fa-solid fa-eye"></i></router-link>
                            </div>
                            <div class="card-body liste " style="background-color: rgba(255, 255, 255, 0.3);min-height: 25vh;max-height: 25vh;overflow-y: auto;">
                                <my-results style="margin-top: -3%; margin-left: 8%;"  :list="list" v-if="list.length"/>
                                <p class="text-white mt-5" v-else> Pas de résultat</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-3">
                        <div class="card" style="background-color: rgba(255, 255, 255,0.2);border-radius: 4%/10%;">
                            <div class="card-header-py text-white p-2">
                                <b>FORMATION</b>
                            </div>
                            <div class="card-body  p-3" style="background-color: white;" v-if="formations && formations.formation">
                                <p><b>Dernière formation</b> : {{ formations.formation.titre }}</p>
                                <p><b>Titre</b> : {{ formations.formation.titre }}</p>
                                <p><b>Difficulté</b> : {{ formations.formation.difficulte }}</p>
                                <p><b>Durée</b> : {{ formations.formation.duree }}</p>
                                <p><b>Date</b> : {{ formations.date_start }}</p>
                            </div>
                            <div class="card-body  p-3" style="background-color: white;" v-else>
                                <p>Pas de résultat</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-3">
                        <div class="card" style="background-color: rgba(255, 255, 255,0.2);border-radius:  4%/10%;">
                            <div class="card-header-py text-white p-2">
                                <b>SANTÉ</b>
                                <router-link class="float-right" to="/themis/my-company"><i class="fa-solid fa-eye"></i></router-link>
                            </div>
                            <div class="card-body p-3" style="background-color: white;">
                                Consultez les documents d'affiliation à la mutuelle
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-3">
                        <div class="card" style="background-color: rgba(255, 255, 255,0.2);border-radius:  4%/10%;">
                            <div class="card-header-py text-white p-2">
                                <b>OBJECTIFS EN COURS</b>
                            </div>
                            <div class="card-body liste p-3" style="background-color: white;max-height: 30vh;overflow-y: auto;">
                                <div  v-if="myMissions.length>0">
                                    <ul  class="list-group" v-for="mission in myMissions" :key="mission.id"> 
                                        <li class="list-group-item">{{ mission.tache }}</li>
                                    </ul>
                                </div>
                                <p v-else>Pas de résultat</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
</template>

<script>
import MyResults from '@/components/MyResults.vue';
 import {getVlinks} from '@/data/apiNess'
 
import data from "@/data/data"
import axios from 'axios'
import { departement, getJobInstance, getMissions, getFormationUser } from '@/data/apiPythie'
    export default {
        name: 'UserProfilePythie',
        components:{ MyResults}, 
        props:[ 'user', 'id'],
        data(){
            return {
                job : {},
                vlinks : 0, 
                list : [], 
                departement :  {}, 
                myMissions : [], 
                formations : []
            }
        }, 
        async mounted(){
            
            this.job = await getJobInstance(this.user.profile.job_id)
            
            this.departement= await departement(this.job.department_id)
            const allVlinks = await getVlinks()
            const one = allVlinks.filter(item=>{
                return item.nom.toLowerCase()===this.user.Lastname.toLowerCase() && item.prenom.toLowerCase()===this.user.Firstname.toLowerCase()
             })
             if(one.length>0)
                this.vlinks = one[0].vlinks
            let result = await axios.get(
                data.baseUrl + "thotea/api/questiongrade/?userId="+this.user.Id+ "&qtyTest=15"
            );
            this.list = result.data;
            this.myMissions  = await getMissions(this.user.Id)
            
            const allFormations = await getFormationUser(this.user.Id)
            if(allFormations.length>0){
                this.formations = allFormations.reduce((prev, current) =>
                    prev.date_start > current.date_start ? prev : current,
                );
            }
        },
        methods:{
            getAge(){
                const now = new Date()
                let age = parseInt((now.getFullYear()) - this.user.profile.dob.substring(0,4));
                return (now.getMonth()<new Date(this.user.profile.dob).getMonth() ? age-1 : age)
            },
            sortedDepartments() {
                return this.departement.extension.sort((a, b) => a.scale - b.scale);
            }, 
            formatDate(dateString){
                let date = new Date(dateString)
                let day = date.getDate();
                let year = date.getFullYear();
                let months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
                let monthName = months[date.getMonth()];
                return day +' '+ monthName+ ' '+year
            }

        }
    }

</script>
<style>
    .step-indicator {
      display: flex;
      justify-content: space-between;
      margin: 20px;
    }

    .step {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      color: goldenrod;
      font-weight: bold;
      margin-top: 20px;
      /* white-space: nowrap;
      overflow: hidden; 
      text-overflow: ellipsis;  */
      text-align: center; 
    }
</style>