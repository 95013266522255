<template>
    <div class="app" id="ness">
      <Header :user="user" page="reseaux" logo="ness"/>
      <div class="row">
      <back-button />
      <br>
    </div>
    <div align="center">
     <h2 class="cardTitle p-2 pl-5 pr-5 col-5 text-center" style="font-weight: bold;">Paramètre des sites</h2>
    </div>
    <div class="row pl-5 pr-5 pb-4 pt-5">
        <div class="col-md-12 card " style="background-color: rgba(255, 255, 255, 0);border: 7px solid white;border-radius: 4% / 14%;height: 55vh;">
            <div class="row" style="height: 100%;">
                <div class="col-md-3" style="height: 100%;overflow-y: auto;background-color: white;border-top-left-radius: 10% 13%; border-bottom-left-radius: 10% 13%;">
                    <ul class="list-group p-4">
                        <li class="m-3 list-item-group p-2" v-for="type in typeReseaux" :key="type.id" :id="'type'+type.id" @click="onChangeType(type.id)" style="cursor: pointer;list-style: none;font-size: large;">
                           {{type.libelle}}
                        </li>
                    </ul>
                </div>
                <div class="col-md-9" style="background-color: rgba(255, 255, 255, 0); " >
                  <div class="row pr-3 pl-3 pt-3 pb-1" style="max-height: 54vh; overflow-y: auto;">
                      <div class="col-md-12">
                        <table class="table borderless" style="width: 100%;">
                            <thead class="textTitle">
                                <th>Nom du site</th>
                                <th>Actif</th>
                                <th>Coefficient</th>
                            </thead>
                            <tbody>
                                <tr  v-for="reseau in listReseaux" :key="reseau.id">
                                    <td class="text-white pt-3">{{reseau.domain}}</td>
                                    <td class="pt-3"><input type="checkbox" style="cursor: pointer;" :checked="reseau.actif" @change="reseau.actif=!reseau.actif"></td>
                                    <td ><button class="btn"  @click="config(reseau)"><i class="fa-solid fa-pen-to-square" style="color: darkcyan;"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                         <div class="btn-bottom" style="background-color: #866c49bf;">
                             <button class="col-md-4 btn btn-lg btn-warning " @click="updateNetworks()">Appliquer</button>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    
    
    <ConfigCoeff :site="site" v-show="showConfig" :parameters="parameters" @updateCoeff="updateCoeff" @togglePopup="toogleshowConfig">

    </ConfigCoeff>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
import store from '@/store';
import {typeReseaux, reseauxByType, getParameter, updateNetwork} from '@/data/apiNess'
import ConfigCoeff from './ConfigCoeff.vue';
import { useToast} from 'vue-toastification';
import Chatroom from '@/components/chatroom/Chatroom.vue';
const toast = useToast()
  export default {
    name: "gestNetwork",
    props: {},
  
    components: {
    BackButton,
    Header,
    ConfigCoeff,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user, 
        typeReseaux : [], 
        typeId : 0, 
        listReseaux : [], 
        site : {}, 
        showConfig : false,
        parameters : {}
      };
    },
    async mounted(){
      this.typeReseaux = await typeReseaux()
      if(this.typeReseaux.length>0 ){
        this.typeId = this.typeReseaux[0].id
        this.$nextTick(() => {
          document.getElementById('type' + this.typeId).classList.add('active');
        });
      }
      this.listReseaux = await reseauxByType(this.typeId)
    },
    methods:{
      async onChangeType(id){
        document.getElementById('type' + this.typeId).classList.remove('active');
        this.typeId = id
        this.listReseaux = await reseauxByType(this.typeId)
          document.getElementById('type' + this.typeId).classList.add('active');
      },
      toogleshowConfig(){
        this.showConfig = !this.showConfig
      }, 
      async config(reseau){
        this.parameters = await getParameter(reseau.id)
        this.site=reseau;
        this.toogleshowConfig()
      },
      updateCoeff(indice, value) {
            const updatedParameters = { ...this.parameters };
            updatedParameters.coefficients[indice].valeur += value;
            updatedParameters.coefficients[indice].valeur = parseFloat(updatedParameters.coefficients[indice].valeur.toFixed(2));
            this.parameters = updatedParameters;
      },
      async updateNetworks(){
        var payload = {
            "reseaux"  : this.listReseaux
        }
        const updated = await updateNetwork(payload)
        toast.success(JSON.stringify(updated))
      }
    }
  
  };
  </script>
<style>
li.active{
  background-color: goldenrod;
  color: white;
  border-radius: 10% /30%;
}
li:not(.active) small {
    color: gold;
}
.btn-bottom{
    position: sticky;
    bottom: 0;
    /* background-color: goldenrod; */
  
}
</style>