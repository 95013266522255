<template>
    <div class="app" id="ness">
      <Header :user="user" page="reseaux" logo="ness"/>
      <div class="row">
      <back-button />
      <br>
    </div>
    <div class="row p-5">
        <div class="col-md-3 pr-3 pl-3">
            <div class="cardTitle p-2">
              <h3 class="textTitle" style="font-weight: bold;">Mes réseaux</h3>
              <!-- <p>Equitis Romani autem </p> -->
            </div>
            <br>
            <h5 class="text-white p-1">Voici la liste des réseaux sur lesquels sont effectués les recherhes</h5>
            <br>
            <div>
              <router-link to="/ness/network/add/"><button class="btn rounded-circle btn-lg" style="background-color: darkcyan;width: 11vh;height: 11vh;"><i class="fa-solid fa-plus" color="white" style="font-size: larger;"></i></button></router-link>
            </div>
            <div class="mt-4">
              <router-link to="/ness/search"><button class="btn rounded-circle btn-lg" style="background-color: white;width: 11vh;height: 11vh;"><i class="fa-solid fa-magnifying-glass" color="gold" style="font-size: larger;"></i></button></router-link>
            </div>
            <div class="mt-4">
              <router-link to="/ness/network-request/"><button class="btn rounded-circle btn-lg" style="background-color: white;width: 11vh;height: 11vh;"><i class="fa-solid fa-list" color="gold" style="font-size: larger;"></i> </button></router-link>
            </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-8 card " style="background-color: rgba(255, 255, 255, 0);border: 7px solid white;border-radius: 4% / 14%;height: 60vh;">
            <div class="row" style="height: 100%;">
                <div class="col-md-4" style="height: 100%;overflow-y: auto;background-color: white;border-top-left-radius: 10% 13%; border-bottom-left-radius: 10% 13%;">
                    <ul class="list-group p-4" style="height: 100%;">
                        <li class="m-3 list-item-group p-2" v-for="type in typeReseaux" :key="type.id" :id="'type'+type.id" @click="onChangeType(type.id)" style="cursor: pointer;list-style: none;font-size: large;">
                           {{type.libelle}}
                        </li>
                    </ul>
                </div>
                <div class="col-md-8" style="background-color: rgba(255, 255, 255, 0); " >
                  <div class="row p-3" style="max-height: 55vh; overflow-y: auto;">
                      <div class="col-md-3" v-for="reseau in listReseaux" :key="reseau.id">
                        <img style="width: 50%;" :src="reseau.logo"/>
                        <p class="text-white mt-1">{{reseau.domain}}</p>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
import store from '@/store';
import {typeReseaux, reseauxByType} from '@/data/apiNess'
import Chatroom from '@/components/chatroom/Chatroom.vue';
  export default {
    name: "reseauxNess",
    props: {},
  
    components: {
    BackButton,
    Header,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user, 
        typeReseaux : [], 
        typeId : 0, 
        listReseaux : []
      };
    },
    async mounted(){
      this.typeReseaux = await typeReseaux()
      if(this.typeReseaux.length>0 ){
        this.typeId = this.typeReseaux[0].id
        this.$nextTick(() => {
          document.getElementById('type' + this.typeId).classList.add('active');
        });
      }
      this.listReseaux = await reseauxByType(this.typeId)
      
    },
    methods:{
      async onChangeType(id){
        document.getElementById('type' + this.typeId).classList.remove('active');
        this.typeId = id
        this.listReseaux = await reseauxByType(this.typeId)
          document.getElementById('type' + this.typeId).classList.add('active');
      }
    }
  
  };
  </script>
<style>
li.active{
  background-color: goldenrod;
  color: white;
  border-radius: 10% /30%;
}
</style>