<template>
    <div class="popup">
        <div class="popup-inner">
            <slot/>
            <div class="  p-3 ">  
                
                <form class="p-4">
                    
                    <div class="pb-3" v-if="question" >
                        <div class="form-group">
                            <label for="title" class="float-left text-white">Question</label>
                            <input type="text" class="form-control" v-model="questionForm" id="question"/>
                        </div>
                        <div class="form-group" >
                            <label for="responses" class="float-left text-white">Réponses (séparées par '|||')</label>
                            <input type="text" class="form-control" v-model="answersForm" id="responses"/>
                        </div>
                        <div class="form-group">
                            <label for="correctAnswer" class="float-left text-white">Bonne(s) réponse(s) (séparées par '|||')</label>
                            <input type="text" class="form-control" v-model="correctAnswerForm" id="correctAnswer"/>
                        </div>
                        <div class="form-group">
                            <label for="level" class="float-left text-white">Niveau</label>
                            <input type="number" min="1" max="3" class="form-control" v-model="difficultyForm" id="level"/>
                        </div>
                        
                    </div>
                   
                    <button type="button" class="btn btn-secondary m-2" @click="togglePopup" >Annuler</button>
                    <button type="button" class="btn btn-success m-2" @click="editQuestion()" >Terminer</button>
                </form> 
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

const toast = useToast()
    import data from '@/data/data';
    export default{
        name: 'editQuestion',
        props:["question", "togglePopup"],
        data(){
            return {
                questionForm : '', 
                answersForm : '', 
                correctAnswerForm : '', 
                difficultyForm : 1
            }
        }, 
        mounted(){
            this.questionForm = this.question.question
            this.answersForm = this.question.answers
            this.correctAnswerForm = this.question.correctAnswer
            this.difficultyForm = this.question.difficulty
        },
        methods:{
            editQuestion(){
                var payload = {
                    "question" : this.questionForm,
                    "answers" : this.answersForm, 
                    "correctAnswer" : this.correctAnswerForm, 
                    "difficulty" : this.difficultyForm
                }
                console.log(payload)
                axios.put(data.baseUrl + 'thotea/api/getThemeQuestion/'+this.question.id+'/', payload)
                    .then(response=>{
                        if(response.data){
                            toast.success("Question mise à jour avec succès")
                            this.togglePopup()
                        }
                    }).catch(error=> console.log(error))
            },  
        }
    }
</script>
<style>
.popup{
    position: fixed;
    top:0;
    left : 0;
    right: 0;
    bottom : 0;
    z-index: 99;
    background-color: rgba(0,0, 0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.popup-inner{
        background-color: #280913fa;
        border: 1px solid;
        border-color: #fab83380;
        width: 50%;
        padding: 32px;
        max-height: 100vh;
        overflow-y: auto;
    }
.questions{
    max-height: 40vh;
    overflow-y: auto;
}
</style>