<template>
    <div class="app" id="ness">
      <Header :user="user" page="histories" logo="ness"/>
      <div class="row">
      <back-button />
      <br>
    </div>
    <br>
      <p class="text-white">Date de recherche : {{ formatDate(history.dater, "DD/MM/YYYY HH:mm:ss") }}</p>
    <div class="row p-5">
        <div class="col-md-3 pr-2 pl-2 ">
            <div class="cardTitle p-3" style="border-radius: 4%/10%;">
              <button class="btn rounded-circle btn-lg" style="background-color: white;" @click="checkFavoris(history.id)"><i id="icon" class="fa-solid fa-star" :style="{ color: isFavori ? 'gold' : 'grey' }"></i></button>
                <div class="row">
                    <strong class="col-6" style="float: left;"><b class="textTitle">Prénom : </b>{{ history.prenom }}</strong>
                    <strong class="col-6 float-right"><b class="textTitle">Nom : </b> {{ history.nom }}</strong>
                </div>
                
                <br>
                <div class=" justify-content-center">
                    <i class="fa-solid fa-link " style="color: gold;font-size: xx-large;"></i>
                    <b class=" pr-5 pl-4" style="color: gold;">E-reputation: </b>
                    <p class="pr-5 pl-5 pt-2" style="color: gold;">{{history.know}} ({{ history.unknow }})VLINKS </p>
                </div>
            </div>
            <br>
           
            <div>
              <button class="btn rounded-circle btn-lg" style="background-color: white;width: 11vh;height: 11vh;" @click="research()">
                <i class="fa-solid fa-cloud" color="gold" style="font-size: larger;"></i>
                <p style="font-size: xx-small;color: gold;">Actualiser</p>
                <!-- <img src="@/assets/ness/historique.png"/> -->
              </button>
            </div>
            <div class="mt-4">
              <router-link to="/ness/histories/"><button class="btn rounded-circle btn-lg" style="background-color: white;width: 11vh;height: 11vh">
                <i class="fa-solid fa-clock-rotate-left" color="gold" style="font-size: larger;"></i>
                <p  style="font-size: xx-small;color: gold;">Voir historiques</p>
              </button></router-link>
            </div>
        </div>
        <div class="col-1"></div>
        <div class="col-8  pr-4  card " style="background-color: rgba(255, 255, 255, 0);border: 7px solid white;border-radius: 4% / 14%;height: 55vh;">
            <div class="row" style="height: 100%;">
                
                <div class="col-4" style="height: 100%; overflow-y: auto;background-color: white;border-radius: 10% / 13%;max-height: 55vh;overflow-y: auto;">
                    
                    <ul class="list-group ">
                        <li class="m-3 p-2 list-item-group pr-5 pl-5" v-for="(type) in history.types" :key="type.id" :id="'type'+type.id" @click="onChangeType(type.data, type.id)" style="cursor: pointer;list-style: none;font-size: large;">
                           <b>{{type.reseau}} </b>
                           <br><br>
                           <p><small style="float: left;">Nbre liens : {{ type.somme_liens }}</small>
                            <small style="float: right;"  ><i class="fa-solid fa-link " style="font-size: x-small;" ></i>  {{ type.somme_valid }}</small>
                          </p>
                        </li>
                    </ul>
                </div>
                <div class="col-8" style="background-color: rgba(255, 255, 255, 0); " >
                  <div class="row p-3" style="max-height: 55vh; overflow-y: auto;">
                        <table class="table text-white" v-if="dataType" style="width: 100%;">
                          <tbody>
                            <tr v-for="type in dataType" :key="type.id" style="border-top: none!important">
                              <td style="border-top: none!important; font-weight: bold;">{{ type.titre }} </td>
                              <td style="border-top: none!important"><a :href="type.url" target="_blank">{{ type.url }}</a></td>
                              <td style="border-top: none!important" v-if="type.validated "><i class="fa-solid fa-circle-check"></i>  </td>
                              <td style="border-top: none!important" v-else><i class="fa-solid fa-circle-xmark"></i></td>
                            </tr>
                          </tbody>
                        </table>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import Chatroom from '@/components/chatroom/Chatroom.vue';
  import moment from 'moment';
import store from '@/store';
import {getHistory, getIdHistory, getFavoris, addFavori, removeFavori} from '@/data/apiNess'
import { useToast } from 'vue-toastification';
const toast = useToast()
  export default {
    name: "historyNess",
    props: {},
  
    components: {
      BackButton,
      Chatroom,
      Header,
    },
    async created(){
      
      this.favoris = await getFavoris(this.user.Id)
    },
    data() {
      return {
        user : store.state.user,
        idHistory : this.$route.params.id, 
        history : {},
        dataType : [], 
        idtype : 0,
        favoris : [], 
        showCollab : store.state.showCollab,
        isFavori :false
      };
    },
    async mounted(){
      this.history = await getHistory(this.idHistory)
      this.isFavori = this.isFavoris(this.history.id)
      if(!JSON.stringify(this.history).includes('Profil_not_found')){
        if(this.history.types.length>0)
            this.dataType = this.history.types[0].data
        this.idtype = this.history.types[0].id
        this.$nextTick(() => {
          document.getElementById('type' + this.history.types[0].id).classList.add('active');
        });
      }
    },
    methods:{
        onChangeType(data, typeId){
          document.getElementById('type' + this.idtype).classList.remove('active');
          this.dataType = data
          this.idtype = typeId
          document.getElementById('type' + this.idtype).classList.add('active');
        },
        async research(){
          var payload = {
                "nom" : this.history.nom,
                "prenom" : this.history.prenom,
                "user" : this.user.Id, 
            }
            const histo = await getIdHistory(payload)
            this.$router.push('/ness/histories/'+histo.historique_id)
        }, 
        isFavoris(idCandidat){
          const index = this.favoris.findIndex(item=>{
            return item.id_candidat === idCandidat
          })

          if(index!=-1) return true
          else return false
        },
        async addFavori(idCandidat){

          let response = {}
          const index = this.favoris.findIndex(item => item.id_candidat == idCandidat);

          if(index==-1){
            // console.log("ADD icon-"+idCandidat)
            response = await addFavori(idCandidat, this.user.Id)
            
            document.getElementById('icon').style.color = 'gold'
            this.favoris.push({ id_candidat: idCandidat})
          }
          else {
            // console.log("REMOVE icon-"+idCandidat)
            document.getElementById('icon').style.color ='rgb(131, 133, 133)'
            response = await removeFavori(idCandidat, this.user.Id)
            this.favoris.splice(index, 1)
          }
          toast.success(JSON.stringify(response))
        },
        checkFavoris(id){
          if(!this.showCollab){
            console.log("AJOUT")
            this.addFavori(id)
          }
        }, 
        formatDate(date, format){
            const parsedDate = moment(date, moment.ISO_8601, true);
            return parsedDate.isValid()?  parsedDate.format(format) : ' - '
        },
    }
  
  };
  </script>
  