<template>
    
    <Header :user="user" logo="themis" page="agreements"/>
    
    <div class="row p-5">
        <div class="col-md-3 liste" style="max-height: 60vh;overflow-y:auto ;">
            
            <ul class="list-group mt-3" style="background-color: rgba(255, 255, 255, 0);">
                <li class=" btn btn-outline-info m-3 p-3" v-for="cctitle in ccTitles" :key="cctitle.id" :id="'cctitle'+cctitle.id" @click="onChangeTitle(cctitle.id)">
                    {{ cctitle.name }}
                </li>
            </ul>
        </div>
        <div class="col-md-9 mt-3" style="border: 5px solid white;border-radius: 4% / 14%;height: 60vh;">
            <div class="row" style="height: 100%">
                <div class="col-md-4 liste" style="height: 100%;overflow-y: auto;background-color: white;border-top-left-radius: 10% 13%; border-bottom-left-radius: 10% 13%;">
                    <ul class="list-group mt-2" style="height: 100%">
                        <li class="m-3 list-item-group p-2" v-for="subtitle in filteredSubtitles()" :key="subtitle.id" :id="'ccsubtitle'+subtitle.id" @click="onChangeSubtitle(subtitle.id, subtitle)" style="cursor: pointer;list-style: none;font-size: large;"  >
                            {{ subtitle.name }} 
                        </li>
                    </ul>
                </div>
                <div class="col-md-8 text-white">
                    <p class="mt-5" v-if="content.length==0">Aucun article</p>
                    <div v-else style="max-height:59vh; overflow-y: auto;">
                        <input class="mt-3 form-control fieldLogin" placeholder="Recherche" v-model="search" style="border: 1px solid rgba(255, 255, 255, 0);"/>
                        <hr>
                        <ul v-if="content.length>0" class="list-group">
                            <li v-for="contenu in filteredContent()" :key="contenu.id" class="list-group-item text-white m-2" style="background-color: #ffffff00;cursor: pointer;border: 1px solid rgba(255, 255, 255, 0.285);" @click="showContent(contenu)">
                                <strong class="  float-left">{{contenu.texte}}</strong>
                                <span class="float-right"><i class="fa-solid fa-angles-right"></i></span>
                            </li>
                        </ul>  
                    </div>
                </div>
            </div>
        </div>
        <LaborCodeContent  :content="contenu" v-show="show" :link="link">
            <button type="button" class="btn  float-right text-white" @click="show=false" >x</button>
            {{ link }}
            <br><br>
        </LaborCodeContent>
    </div>
    <Chatroom/>
</template>
<script>
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import LaborCodeContent from './popups/LaborCodeContent.vue';
import {getCCTitle, getCCSubtitle, getCCArticles} from '@/data/apiThemis'
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'ThemisCollectiveAgreements',
        components: { Header, LaborCodeContent, Chatroom }, 
        data(){
            return{
                user: store.state.user,
                ccTitles : [], 
                ccSubtitles : [], 
                idTitle : -1,
                idSubtitle : -1,
                content : [], 
                search : '', 
                show:false, 
                contenu : {}, 
                link : {}
            }
        },
        async mounted(){
            this.ccTitles = await getCCTitle()
            if(this.ccTitles.length>0)
                this.idTitle = this.ccTitles[0].id
            this.ccSubtitles = await getCCSubtitle()
            const funct = async ()=>{
                this.content = await getCCArticles(this.idSubtitle)
            }
            this.$nextTick(() => {
                if(document.getElementById('cctitle' + this.idTitle))
                    document.getElementById('cctitle' + this.idTitle).classList.add('active');
                if(this.filteredSubtitles().length>0){
                    this.idSubtitle = this.filteredSubtitles()[0].id
                    if(document.getElementById('ccsubtitle' + this.idSubtitle)){
                        document.getElementById('ccsubtitle' + this.idSubtitle).classList.add('active');
                        funct()
                    }
                }
            });
        },
        methods:{
            filteredSubtitles(){
                if(this.idTitle!=-1){
                    const result =  this.ccSubtitles.filter(item=>{
                        return item.idTitre == this.idTitle
                    })
                    return result
                }
            }, 
            async onChangeTitle(idTitle ){
                
                if(document.getElementById('cctitle' + this.idTitle))
                document.getElementById('cctitle' + this.idTitle).classList.remove('active');
                this.idTitle = idTitle
                document.getElementById('cctitle' + this.idTitle).classList.add('active');
                if(this.filteredSubtitles().length>0){
                        this.idSubtitle = this.filteredSubtitles()[0].id
                        if(document.getElementById('ccsubtitle' + this.idSubtitle))
                        document.getElementById('ccsubtitle' + this.idSubtitle).classList.add('active');
                        this.content = await getCCArticles(this.idSubtitle)
                }
                
            },
            async onChangeSubtitle(idSubtitle, subtitle){
                this.link=subtitle.lien
                document.getElementById('ccsubtitle' + this.idSubtitle).classList.remove('active');
                this.idSubtitle = idSubtitle
                document.getElementById('ccsubtitle' + this.idSubtitle).classList.add('active');
                this.content = await getCCArticles(this.idSubtitle)
                
            },
            filteredContent(){
                if(this.search!='')
                {
                    return this.content.filter(item=>{
                        return item.texte.toLowerCase().includes(this.search.toLowerCase())
                    })
                }
                return this.content
            },
            showContent(contenu){
                const contenuu= contenu
                contenuu.contenu = contenu.contenu.replaceAll('\n', '<br>')
                this.contenu = contenuu
                this.show = true
            }
        }
    }
</script>