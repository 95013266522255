<template>
  <div class="app" id="thotea">
    <Header :user="user" logo="thotea"/>
    <back-button />
    <br>
    
    <CandidatsAnswer :list="list" :listName="listName"/>
    <Chatroom />
  </div>
</template>

<script>

import axios from "axios";

import BackButton from "@/components/shared/BackButton.vue";
import CandidatsAnswer from "@/components/DetailResult/CandidatsAnswer.vue";
import Header from '@/components/shared/Header.vue';
import store from '@/store';
import data from '@/data/data';
import Chatroom from "@/components/chatroom/Chatroom.vue";

export default {
  name: "DetailResult",
  props: {},

  components: {
    BackButton,
    CandidatsAnswer,
    // NavBar,
    Header,
    Chatroom
},

  data() {
    return {
      list: [],
      index: 0,
      item: {},
      user : store.state.user,

      listName:[],
      itemName:{},
      lastValue : this.$route.params.idQuest,
      userId: this.$route.params.userId,

    };
  },

  async mounted() {
    let result = await axios.get(
      data.baseUrl + 'thotea/api/historics?user_id=' + this.userId + '&&questionnaire_id=' + this.lastValue
    );
    //console.log(result.data);
    this.list = result.data;
    this.item = this.list[0];

    let questionnaireName = await axios.get(
      data.baseUrl + 'thotea/api/questiongrade/?userId=' + this.user.Id
    );
    //console.log(questionnaireName.data);
    this.listName = questionnaireName.data;
    this.itemName = this.listName[0];
  },
};
</script>
